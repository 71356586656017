import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo2.png";
export default function DisconnectComponent() {
  const navigate = useNavigate();

  return (
    <div>
  
    <div className="disconnect-container">
    <img
              src={logo}
              alt="Logo"
              className="sidebar_disconnectLogo"
              onClick={() => navigate("/home")}
            />
      <div className="disconnect-card">
        <h2 className="disconnect-title">Disconnected from QuickBooks</h2>
        <p className="disconnect-message">Your connection to QuickBooks has been successfully disconnected.</p>

        <div className="disconnect-section">
          <h3>🚨 Implications of Disconnecting</h3>
          <ul className="disconnect-list">
            <li>You will no longer be able to sync data with QuickBooks.</li>
            <li>A reconnection is required to continue using QuickBooks services.</li>
          </ul>
        </div>

        <div className="disconnect-section">
          <h3>🔄 How to Reconnect</h3>
          <ol className="disconnect-list">
            <li><b>Go to your Financial Data</b> and click <b>Select Input Method</b> and select <b>QuickBooks</b>.</li>
            <li><b>Click "Connect to QuickBooks"</b> and sign in.</li>
            <li><b>Authorize access</b> to sync your data again.</li>
          </ol>
        </div>

        <button className="disconnect-button" onClick={() => navigate("/")}>
          Back to Home
        </button>
      </div>
    </div></div>
  );
}
