const initialState = {
  actionLogs: [],
  recentActionLog: {},
  hiddenCashActionLogs: [],
  fmfActionLogs: [],
  homeRunActionLogs: [],
  simpleValActionLogs: [],
  startWithEndActionLogs: [],
  financialDoctorActionLogs: [],
  actionLogLoading: false,
};

const actionLogReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ACTION_LOG":
      return { ...state, recentActionLog: action.payload };
    case "GET_ACTION_LOGS":
      return { ...state, actionLogs: action.payload };
    case "GET_HIDDEN_CASH_ACTION_LOGS":
      return { ...state, hiddenCashActionLogs: action.payload };
    case "GET_FMF_ACTION_LOGS":
      return { ...state, fmfActionLogs: action.payload };
    case "GET_ACTION_LOG_BY_COMPANY":
      return { ...state, actionLogs: action.payload };
    case "GET_HOME_RUN_ACTION_LOGS":
      return { ...state, homeRunActionLogs: action.payload };
    case "GET_START_WITH_END_ACTION_LOGS":
      return { ...state, startWithEndActionLogs: action.payload };
    case "GET_SIMPLE_VALUATION_ACTION_LOGS":
      return { ...state, simpleValActionLogs: action.payload };
    case "GET_FINANCIAL_DOCTOR_ACTION_LOGS":
      return { ...state, financialDoctorActionLogs: action.payload };
    case "RESET_LOG_STATES":
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default actionLogReducers;
