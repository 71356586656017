import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { clearSnack } from "../../utils/utilFunctions";

export const ADD_ACTION_LOG = "ADD_ACTION_LOG";
export const GET_ACTION_LOGS = "GET_ACTION_LOGS";
export const GET_HIDDEN_CASH_ACTION_LOGS = "GET_HIDDEN_CASH_ACTION_LOGS";
export const GET_FMF_ACTION_LOGS = "GET_FMF_ACTION_LOGS";
export const GET_HOME_RUN_ACTION_LOGS = "GET_HOME_RUN_ACTION_LOGS";
export const EDIT_ACTION_LOG = "EDIT_ACTION_LOG";
export const DELETE_ACTION_LOG = "DELETE_ACTION_LOG";
export const GET_SIMPLE_VALUATION_ACTION_LOGS =
  "GET_SIMPLE_VALUATION_ACTION_LOGS";
export const GET_START_WITH_END_ACTION_LOGS = "GET_START_WITH_END_ACTION_LOGS";
export const GET_FINANCIAL_DOCTOR_ACTION_LOGS =
  "GET_FINANCIAL_DOCTOR_ACTION_LOGS";
export const RESET_LOG_STATES = "RESET_LOG_STATES";

export const addActionLog = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}/actionLog/add`, payload);
    dispatch({ type: ADD_ACTION_LOG, payload: response.data });

    dispatch({
      type: "OPEN_SNACK",
      payload: {
        message: "Action log add successful",
        snackType: "success",
      },
    });
    clearSnack(dispatch);
    return response;
  } catch (error) {
    dispatch({
      type: "OPEN_SNACK",
      payload: {
        message: "Error adding action log",
        snackType: "error",
      },
    });
    clearSnack(dispatch);
  }
};

export const resetLogs = () => (dispatch) => {
  dispatch({
    type: RESET_LOG_STATES,
  });
};

export const getActionLogByCompany = (payload) => async (dispatch) => {
  try {
    if (!payload.companyId) return; //app crashing because companyId is undefined
    const response = await axios.post(
      `${BASE_URL}/actionLog/company/${payload.companyId}`,
      payload
    );

    console.log("payload.type", payload.type);

    switch (payload.type) {
      case "HiddenCash":
        dispatch({
          type: GET_HIDDEN_CASH_ACTION_LOGS,
          payload: response.data.actionLogs,
        });
        break;

      case "FMF":
        dispatch({
          type: GET_FMF_ACTION_LOGS,
          payload: response.data.actionLogs,
        });
        break;

      case "HomeRun":
        dispatch({
          type: GET_HOME_RUN_ACTION_LOGS,
          payload: response.data.actionLogs,
        });
        break;

      case "SimpleValuation":
        dispatch({
          type: GET_SIMPLE_VALUATION_ACTION_LOGS,
          payload: response.data.actionLogs,
        });
        break;
      case "StartWithEnd":
        dispatch({
          type: GET_START_WITH_END_ACTION_LOGS,
          payload: response.data.actionLogs,
        });
        break;
      case "FinancialDoctor":
        dispatch({
          type: GET_FINANCIAL_DOCTOR_ACTION_LOGS,
          payload: response.data.actionLogs,
        });
        break;
      default:
        dispatch({ type: GET_ACTION_LOGS, payload: response.data.actionLogs });
        break;
    }
  } catch (error) {
    console.log(error);
  }
};
export const getActionLogs = (payload) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/actionLog`, payload);
    dispatch({ type: GET_ACTION_LOGS, payload: response.data });
  } catch (error) {
    console.log(error);
  }
};

export const editActionLog = (actionLogId, payload) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/actionLog/update/${actionLogId}`,
      payload
    );
    dispatch({ type: EDIT_ACTION_LOG, payload: response.data });

    dispatch({
      type: "OPEN_SNACK",
      payload: {
        message: "Action log updat successful",
        snackType: "success",
      },
    });
    clearSnack(dispatch);
    return response;
  } catch (error) {
    dispatch({
      type: "OPEN_SNACK",
      payload: {
        message: "Error updating action log",
        snackType: "error",
      },
    });
    clearSnack(dispatch);
  }
};

export const deleteActionLog = (actionLogId) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/actionLog/delete/${actionLogId}`
    );
    dispatch({ type: DELETE_ACTION_LOG, payload: response.data });
  } catch (error) {
    console.log(error);
  }
};
