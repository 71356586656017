import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { Layout } from "../../layout";
import { CardWrapper, Accordian } from "../../components";
import {
  Grid,
  Typography,
  Box,
  Tooltip,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import Analytics from "../../assets/analytics-icon.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { fetchRangDate, fetchYears } from "../../redux/actions/homeRun";
import currency_symbols from "../../utils/currency.js";
import ArrowUp from "../../images/up.png";
import ArrowDown from "../../images/down.png";
import Postive from "../../images/positive.png";
import Negative from "../../images/negative.png";
import TrendsChart from "../../components/lineCharts/trendsChart.jsx";
import ExpenseControlChart from "../../components/lineCharts/expenseControlChart.jsx";
import DeptToEquityRatioChart from "../../components/lineCharts/deptToEquityRatioChart.jsx";
import EBITDA from "../../components/lineCharts/ebitda.jsx";
import MisMatchedChart from "../../components/lineCharts/misMatchedChart.jsx";
import CashFlowActivityPatternChart from "../../components/lineCharts/cashFlowActivityPatternChart.jsx";
import { fetchHRFSNotes } from "../../redux/actions/notes.js";
import html2canvas from "html2canvas";
import { updatePDFData } from "../../redux/actions/pdfGraph.js";
import { handlInfo } from "../../utils/utilFunctions.js";
import ActionLogTable from "../../components/action-log-table/index.jsx";
import { getActionLogByCompany } from "../../redux/actions/actionLog.js";

const periodDuration = [
  { label: "Year" },
  { label: "Quarter" },
  { label: "Month" },
  { label: "Year To Date" },
];

const HomeRun = () => {
  const dispatch = useDispatch();

  const {
    periods,
    industryAverage,
    financialData,
    company,
    companyDetailsLoading,
  } = useSelector((state) => state.companyDetailReducer);

  const trendsRef = useRef(null);
  const exCntrlRef = useRef(null);
  const debtEquityRef = useRef(null);
  const ebitdaRef = useRef(null);
  const misMatchRef = useRef(null);
  const cashFlowActRef = useRef(null);

  const trendsNotesRef = useRef(null);
  const exCntrlNotesRef = useRef(null);
  const debtEquityNotesRef = useRef(null);
  const ebitdaNotesRef = useRef(null);
  const misMatchNotesRef = useRef(null);
  const cashFlowActNotesRef = useRef(null);

  const {
    trendsNotes,
    cashFlowActvtyNotes,
    misMatchNotes,
    ebidtaNotes,
    debtEqRatioNotes,
    expenseControlNotes,
  } = useSelector((state) => state.notesReducer);

  const { user } = useSelector((state) => state.userReducer);

  const { homeRunActionLogs } = useSelector((state) => state.actionLogReducer);

  const [yearsData, setYearsData] = useState("");
  const [selectedDateType, setSelectedDateType] = useState("Year");
  const [fromDate, setFromDate] = useState("");
  const [slicedArray, setSlicedArray] = useState([]);
  const [toSelectedDate, setToSelectedDate] = useState("");

  const [trends, setTrends] = useState("");
  const [expenseControl, setExpenseControl] = useState("");
  const [deptToEquityRatio, setDeptToEquityRatio] = useState("");
  const [ebitda, setEbitda] = useState("");
  const [misMatched, setMisMatched] = useState("");
  const [cashFlow, setCashFlow] = useState("");

  const [isGraphUpdate, setIsGraphUpdate] = useState(false);

  const [range, setRange] = useState("");

  const [trendCal, setTrendCal] = useState({
    sales: "",
    grossProfit: "",
    netProfit: "",
    operatingExpense: "",
  });
  const [expenseControlCal, setExpenseControlCal] = useState({
    grossProfit: "",
    expenseControl: "",
    result: "",
  });
  const [deptToEquityRatioCal, setDeptToEquityRatioCal] = useState({
    liability: "",
    equity: "",
    ratio: "",
  });
  const [ebitdaCal, setEbitdaCal] = useState({
    ebitdaVal: "",
    ltdCapacity: "",
    currentLtd: "",
    borrowingPower: "",
  });
  const [misMatchedCal, setMisMatchedCal] = useState({
    grossFixedAssets: "",
    lognTermDebt: "",
    retainedEarnings: "",
    results: "",
  });
  const [cashFlowCal, setCashFlowCal] = useState({
    operatingActivities: "",
    investingActivities: "",
    financingActivities: "",
  });

  const [reload, setReload] = useState(false);
  const [periodData, setPeriodData] = useState("");
  const { selectedCompany } = useSelector((state) => state.companyReducer);

  useEffect(() => {
    if (Object.keys(company).length) handleDateChange();
  }, [company?.id]);

  const trendsCalculation = (data) => {
    setTrendCal({
      ...trendCal,
      grossProfit:
        data.grossProfite[data.grossProfite.length - 1] - data.grossProfite[0],
      operatingExpense:
        data.operatingExpense[data.operatingExpense.length - 1] -
        data.operatingExpense[0],
      sales: data.sales[data.sales.length - 1] - data.sales[0],
      netProfit: data.netProfit[data.netProfit.length - 1] - data.netProfit[0],
    });
  };
  const expenseControlCalculation = (data) => {
    let totalGrossProfit =
      data.grossProfit[data.grossProfit.length - 1] - data.grossProfit[0];
    let totalOpExpense =
      data.operatingExpense[data.operatingExpense.length - 1] -
      data.operatingExpense[0];
    let result = totalGrossProfit - totalOpExpense;

    setExpenseControlCal({
      ...expenseControl,
      grossProfit: totalGrossProfit,
      expenseControl: totalOpExpense,
      result: result,
    });
  };

  const deptToEquityRatioCalculation = (data) => {
    let totalLiabilities = 0;
    let totalEquity = 0;
    let ratio;

    totalEquity = data.totalEquity[data.totalEquity.length - 1];

    totalLiabilities = data.totalLiabilities[data.totalLiabilities.length - 1];

    ratio = totalLiabilities / totalEquity;
    setDeptToEquityRatioCal({
      ...deptToEquityRatioCal,
      liability: totalLiabilities,
      equity: totalEquity,
      ratio: ratio.toFixed(2),
    });
  };

  const ebitdaCalculation = (data) => {
    let totalEbitda = 0;
    let totalCurrentLtd = 0;

    totalEbitda = data.ebidta[data.ebidta.length - 1];

    totalCurrentLtd = data.currentLTD[data.currentLTD.length - 1];

    setEbitdaCal({
      ...ebitdaCal,
      ebitdaVal: totalEbitda,
      ltdCapacity: totalEbitda * 3,
      currentLtd: totalCurrentLtd,
      borrowingPower: totalEbitda * 3 - totalCurrentLtd,
    });
  };

  const misMatchedCalculation = (data) => {
    setMisMatchedCal({
      ...misMatchedCal,
      grossFixedAssets:
        data.grossFixedAssets[data.grossFixedAssets.length - 1] -
        data.grossFixedAssets[0],
      lognTermDebt:
        data.longTermDebt[data.longTermDebt.length - 1] - data.longTermDebt[0],
      retainedEarnings:
        data.retainedEarnings[data.retainedEarnings.length - 1] -
        data.retainedEarnings[0],
      results:
        data.grossFixedAssets[data.grossFixedAssets.length - 1] -
        data.grossFixedAssets[0] -
        (data.longTermDebt[data.longTermDebt.length - 1] -
          data.longTermDebt[0] +
          (data.retainedEarnings[data.retainedEarnings.length - 1] -
            data.retainedEarnings[0])),
    });
  };

  const cashFlowCalculation = (data) => {
    setCashFlowCal({
      ...cashFlowCal,
      operatingActivities:
        data.operatingActivities[data.operatingActivities.length - 1],
      investingActivities:
        data.investingActivities[data.investingActivities.length - 1],
      financingActivities:
        data.financingActivities[data.financingActivities.length - 1],
    });
  };

  const trendCondition = () => {
    if (
      trendCal.sales > -1 &&
      trendCal.grossProfit > -1 &&
      trendCal.netProfit > -1 &&
      trendCal.operatingExpense > -1
    ) {
      return "Good position to be in. Watch increase in the operting expense above gross profit increase. It could result in the decrease of net profit.";
    } else if (
      trendCal.sales > -1 &&
      trendCal.grossProfit < 0 &&
      trendCal.operatingExpense < 0 &&
      (trendCal.netProfit < 0 || trendCal.netProfit > -1)
    ) {
      return "Growing sales is generally a good sign, but they are losing gross profit dollars. To combat, this company should cut expenses. Check to ensure they cut enough expense to avoid a loss.";
    } else if (
      trendCal.sales > -1 &&
      trendCal.grossProfit > -1 &&
      trendCal.operatingExpense < 0 &&
      trendCal.netProfit > -1
    ) {
      return "Love this!! I consider this pattern to be the perfect senario. One word of caution would be to not cut expenses too much that you can't keep up with growth.";
    } else if (
      trendCal.sales > -1 &&
      trendCal.grossProfit < 0 &&
      trendCal.netProfit < 0 &&
      trendCal.operatingExpense > -1
    ) {
      return "Watch out, this could lead to a “growing broke” situation where you don't have enough money to keep up with sales growth. Focus on increasing gross profit and cutting expenses.";
    } else if (
      trendCal.sales < 0 &&
      trendCal.grossProfit > -1 &&
      trendCal.operatingExpense < 0 &&
      (trendCal.netProfit > -1 || trendCal.netProfit < 0)
    ) {
      return "This one could go either way. Although sales are down, they are generating more cash. Keep an eye on the increase in operating expenses. Too much will result in a loss.";
    } else if (
      trendCal.sales < 0 &&
      trendCal.grossProfit < 0 &&
      trendCal.operatingExpense > -1 &&
      trendCal.netProfit < 0
    ) {
      return "Take action NOW! Sales are decreasing, so is gross profit, This means you have less money to spend but operating expenses are increasing. you can’t have less to spend and spend more.";
    } else if (
      trendCal.sales < 0 &&
      trendCal.grossProfit > -1 &&
      trendCal.netProfit > -1 &&
      trendCal.operatingExpense < 0
    ) {
      return "Although sales are down, gross profit increases and operating expense decreases are helping to maintain profitability.";
    } else if (
      trendCal.sales < 0 &&
      trendCal.grossProfit < 0 &&
      trendCal.operatingExpense < 0 &&
      (trendCal.netProfit > -1 || trendCal.netProfit < 0)
    ) {
      return "Things aren't looking good here. But this company may have cut enough expenses to be able to maintain or increase profit. This company needs top line attention, more sales.";
    }
  };

  const expenseControlCondition = () => {
    if (expenseControlCal.result < -1) {
      return `The company needs to cut an additional ${
        currency_symbols[company.currency]
      }(${Math.ceil(
        Math.abs(expenseControlCal.result)
      ).toLocaleString()}) in operating expenses.`;
    } else {
      return `You can increase operating expense by ${
        currency_symbols[company.currency]
      }${
        expenseControlCal.result < 0
          ? `(${Math.ceil(
              Math.abs(expenseControlCal.result)
            ).toLocaleString()})`
          : `${Math.ceil(Math.abs(expenseControlCal.result)).toLocaleString()}`
      }.`;
    }
  };

  const deptToEquityRatioCondition = () => {
    if (deptToEquityRatioCal.ratio > 2.5) {
      return "Too High. This company might have trouble getting a loan.";
    } else if (deptToEquityRatioCal.ratio > -1) {
      return "Great! This company is managing debt well.";
    } else if (deptToEquityRatioCal.ratio < 0) {
      return "Trouble! This is an indication  of financial distress.";
    }
  };

  const ebitdaCondition = () => {
    if (ebitdaCal.borrowingPower > -1) {
      return `This Company may be able to borrow up to ${
        currency_symbols[company.currency]
      }${
        ebitdaCal.borrowingPower < 0
          ? `(${Math.ceil(
              Math.abs(ebitdaCal.borrowingPower)
            ).toLocaleString()})`
          : `${Math.ceil(Math.abs(ebitdaCal.borrowingPower)).toLocaleString()}`
      } in the long term debt.`;
    } else {
      return "This company may not get approval for a new loan.";
    }
  };

  const misMatchedCondition = () => {
    if (misMatchedCal.results > 0) {
      return "YES. The company may have mis-financed assets! It looks like you might have purchased assets with a short term credit product.  Here are two questions: What was bought?  How was it paid for?  Contact a banker for help.";
    } else if (misMatchedCal.results === 0) {
      return "Nice! There was no mis-matched financing found.";
    } else {
      return "Check to see if any assets were purchased in this time frame.";
    }
  };

  const cashFlowCondition = () => {
    if (
      cashFlowCal.operatingActivities > -1 &&
      cashFlowCal.investingActivities > -1 &&
      cashFlowCal.financingActivities > -1
    ) {
      return "Company is using cash generated from operations and from the sale of assets and from financing to build up a pile of cash - very liquid company, possible looking for acquisition.";
    } else if (
      cashFlowCal.operatingActivities > -1 &&
      cashFlowCal.investingActivities < 0 &&
      cashFlowCal.financingActivities < 0
    ) {
      return "Company is using cash flow generated from operations to buy fixed assets and to pay down debt or pay owners.";
    } else if (
      cashFlowCal.operatingActivities > -1 &&
      cashFlowCal.investingActivities > -1 &&
      cashFlowCal.financingActivities < 0
    ) {
      return " Company is using cash from operations and from sale of fixed assets to pay down debt or pay owners.";
    } else if (
      cashFlowCal.operatingActivities > -1 &&
      cashFlowCal.investingActivities < 0 &&
      cashFlowCal.financingActivities > -1
    ) {
      return "Company is using cash flow from operations and from borrowing (or from owner investment) to expand.";
    } else if (
      cashFlowCal.operatingActivities < 0 &&
      cashFlowCal.investingActivities > -1 &&
      cashFlowCal.financingActivities > -1
    ) {
      return "Company's operating cash flow problems are covered by the sale of fixed assets and by borrowing or by shareholder contributions.";
    } else if (
      cashFlowCal.operatingActivities < 0 &&
      cashFlowCal.investingActivities < 0 &&
      cashFlowCal.financingActivities > -1
    ) {
      return "Company is growing rapidly but has shortfalls in cash from operations and from the purchase of fixed assets financed by long term debt or new financing by owners.";
    } else if (
      cashFlowCal.operatingActivities < 0 &&
      cashFlowCal.investingActivities > -1 &&
      cashFlowCal.financingActivities < 0
    ) {
      return "Company is financing operating cash flow shortages and payments to creditors and or stockholders by selling its fixed assets.";
    } else {
      return "Company is using cash reserves to finance operation shorfalls and pay long term creditors and/or investors.";
    }
  };

  const handleDateChange = async () => {
    // setSelectedDateType(e.target.value);
    const payload = {
      companyId: company._id,
      dateType: "Year",
    };

    const res = await dispatch(fetchYears(payload));
    setYearsData(res);
  };

  const handleFromDate = (ee) => {
    setFromDate(ee.target.value);

    const selectedIndex = yearsData.findIndex(
      (year) => year.date === ee.target.value
    );

    const sliceArray = yearsData.slice(selectedIndex + 1);
    setSlicedArray(sliceArray);

    if (toSelectedDate) {
      const e = {
        target: {
          value: toSelectedDate,
          fromDate: ee.target.value,
        },
      };
      handleToDate(e);
    }
  };

  const handleToDate = async (e) => {
    setToSelectedDate(e.target.value);

    const currentFromDate = e.target.fromDate ? e.target.fromDate : fromDate;

    const filteredDates = yearsData.filter((obj) => {
      const date = new Date(obj.date); // Convert the date string to a Date object
      return (
        date >= new Date(currentFromDate) && date <= new Date(e.target.value)
      );
    });

    const dateArray = filteredDates.map((obj) => obj.periodName);

    console.log(dateArray, "dateAsssrray");

    setRange(dateArray);

    const payload = {
      companyId: company._id,
      fromDate: currentFromDate,
      toDate: e.target.value,
      dateType: selectedDateType,
    };

    const res = await dispatch(fetchRangDate(payload));

    setTrends(res.trends);
    trendsCalculation(res.trends);
    setExpenseControl(res.expenseControl);
    expenseControlCalculation(res.expenseControl);
    setDeptToEquityRatio(res.debtToEquityRatio);
    deptToEquityRatioCalculation(res.debtToEquityRatio);
    setEbitda(res.ebidtaVales);
    ebitdaCalculation(res.ebidtaVales);
    setMisMatched(res.misMatchedFinanc);
    misMatchedCalculation(res.misMatchedFinanc);
    setCashFlow(res.cashFlow);
    cashFlowCalculation(res.cashFlow);

    const payload1 = {
      date: fromDate,
      date1: e.target.value,
      page: "HomeRun",
      companyId: company._id,
    };

    dispatch(fetchHRFSNotes(payload1));
    savePDF();
  };

  useEffect(() => {
    savePDF();
  }, [isGraphUpdate]);

  useEffect(() => {
    const payload = {
      companyId: selectedCompany._id,
      type: "HomeRun",
    };
    dispatch(getActionLogByCompany(payload));
  }, [dispatch, selectedCompany._id, periodData._id, reload]);

  const savePDF = () =>
    setTimeout(() => {
      saveHRFSPDF();
    }, 1500);

  const saveHRFSPDF = async () => {
    dispatch({
      type: "GRAPH_LOADING",
    });
    if (!trendsRef.current) {
      dispatch({
        type: "STOP_GRAPH_LOADING",
      });
      return;
    }

    const trendsCanvas = await html2canvas(trendsRef.current);
    const trendsBase64Image = await trendsCanvas.toDataURL("image/png");

    const exCntrlCanvas = await html2canvas(exCntrlRef.current);
    const exCntrlBase64Image = await exCntrlCanvas.toDataURL("image/png");

    const debtEquityCanvas = await html2canvas(debtEquityRef.current);
    const debtEquityBase64Image = await debtEquityCanvas.toDataURL("image/png");

    const ebitdaCanvas = await html2canvas(ebitdaRef.current);
    const ebitdaBase64Image = await ebitdaCanvas.toDataURL("image/png");

    const misMatchCanvas = await html2canvas(misMatchRef.current);
    const misMatchBase64Image = await misMatchCanvas.toDataURL("image/png");

    const cashFlowActCanvas = await html2canvas(cashFlowActRef.current);
    const cashFlowActBase64Image = await cashFlowActCanvas.toDataURL(
      "image/png"
    );

    const trendsNotesCanvas = await html2canvas(trendsNotesRef.current);
    const trendsNotesImage = await trendsNotesCanvas.toDataURL("image/png");

    const exCntrlNotesCanvas = await html2canvas(exCntrlNotesRef.current);
    const exCntrlNotesImage = await exCntrlNotesCanvas.toDataURL("image/png");

    const debtEquityNotesCanvas = await html2canvas(debtEquityNotesRef.current);
    const debtEquityNotesImage = await debtEquityNotesCanvas.toDataURL(
      "image/png"
    );

    const ebitdaNotesCanvas = await html2canvas(ebitdaNotesRef.current);
    const ebitdaNotesImage = await ebitdaNotesCanvas.toDataURL("image/png");

    const misMatchNotesCanvas = await html2canvas(misMatchNotesRef.current);
    const misMatchNotesImage = await misMatchNotesCanvas.toDataURL("image/png");

    const cashFlowActNotesCanvas = await html2canvas(
      cashFlowActNotesRef.current
    );
    const cashFlowActNotesImage = await cashFlowActNotesCanvas.toDataURL(
      "image/png"
    );

    const payload = {
      notes: {
        trend: trendsNotesImage,
        exCntrl: exCntrlNotesImage,
        debtEquity: debtEquityNotesImage,
        ebitda: ebitdaNotesImage,
        misMatch: misMatchNotesImage,
        cashFlowAct: cashFlowActNotesImage,
      },
      images: {
        trend: trendsBase64Image,
        exCntrl: exCntrlBase64Image,
        debtEquity: debtEquityBase64Image,
        ebitda: ebitdaBase64Image,
        misMatch: misMatchBase64Image,
        cashFlowAct: cashFlowActBase64Image,
      },
    };

    dispatch(updatePDFData(payload));
    dispatch({
      type: "STOP_GRAPH_LOADING",
    });
  };

  const generateRandomLightColor = () => {
    return `#b7959b`;
  };

  const CompanyLogo = ({ companyName }) => {
    // Get the first letter of the company name
    const firstLetter = companyName ? companyName.charAt(0).toUpperCase() : "";

    // Style for the red circle
    const circleStyle = {
      width: "50px", // Set your desired logo size
      height: "50px", // Set your desired logo size
      borderRadius: "50%",
      backgroundColor: generateRandomLightColor(),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: "1.5em",
      marginRight: "10px",
    };

    return <div style={circleStyle}>{firstLetter}</div>;
  };

  return (
    <React.Fragment>
      <Layout title="Home Run">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CompanyLogo companyName={company.companyName} />
            <Typography className="financial_data_heading">
              {company.companyName}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "none",
                      backgroundColor: "#F8F8FF",
                      color: "gray",
                      paddingY: "10px",
                      paddingX: "20px",
                    },
                  },
                }}
                title={
                  <pre style={{ fontSize: "13px", fontWeight: "600" }}>
                    {company.NAICStitle}
                  </pre>
                }
                placement="bottom"
                // arrow
              >
                <Typography
                  className="financial_data_right_heading"
                  sx={{ cursor: "pointer" }}
                >
                  NAICS Code: {company.NAICScode}
                </Typography>
              </Tooltip>
            </Typography>
          </Grid>
        </CardWrapper>
        <Box style={{ height: 10, width: "100%" }} />
        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // marginBottom: 37,
            padding: "20px",
          }}
        >
          <Typography>
            <Typography className="home_my_companies">
              The Home Run Financial System{" "}
              <InfoOutlinedIcon
                onClick={() => handlInfo("hrfs")}
                className="infor_icon"
              />
            </Typography>
            <Box style={{ height: 10, width: "100%" }} />
          </Typography>
          <Grid>
            <Select
              onChange={handleFromDate}
              placeHolder="From Period "
              label="From Period "
              className="financial_data_select"
              defaultValue={"From Period"}
              style={{ marginLeft: 25 }}
            >
              <MenuItem disabled value={"From Period"}>
                From Period
              </MenuItem>
              {yearsData.length === 0 ? (
                <MenuItem value="" disabled>
                  No Options Available
                </MenuItem>
              ) : (
                yearsData.map((year, index) => (
                  <MenuItem key={index} value={year.date}>
                    {year.periodName}
                  </MenuItem>
                ))
              )}
            </Select>

            <Select
              onChange={handleToDate}
              placeHolder="From Period "
              label="From Period "
              className="financial_data_select"
              defaultValue={"To Period"}
              style={{ marginLeft: 25 }}
            >
              <MenuItem disabled value={"To Period"}>
                To Period
              </MenuItem>
              {yearsData.length === 0 ||
              !fromDate ||
              slicedArray.length === 0 ? (
                <MenuItem value="" disabled>
                  No Options Available
                </MenuItem>
              ) : (
                slicedArray.map((year, index) => (
                  <MenuItem key={index} value={year.date}>
                    {year.periodName}
                  </MenuItem>
                ))
              )}
            </Select>
          </Grid>
        </Grid>
        <Box style={{ height: 10, width: "100%" }} />
        <CardWrapper>
          {/* {toSelectedDate ? ( */}
          <>
            <CardWrapper border="1px solid #E7E7E7">
              <Grid container xs={12}>
                <Box style={{ height: 30, width: "100%" }} />
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: 25,
                  }}
                >
                  Trends
                </span>
                <Box ref={trendsRef} style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      {yearsData ? (
                        <TrendsChart trends={trends} range={range} />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            {" "}
                            Sales
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {trendCal.sales < 0
                              ? `(${Math.ceil(
                                  Math.abs(trendCal.sales)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(trendCal.sales)
                                ).toLocaleString()}`}
                          </p>
                        </div>

                        {trendCal.sales >= 0 ? (
                          <img src={ArrowUp} alt="arrow-up" height={55} />
                        ) : (
                          <img src={ArrowDown} alt="arrow-up" height={55} />
                        )}
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            {" "}
                            Gross Profit
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {trendCal.grossProfit < 0
                              ? `(${Math.ceil(
                                  Math.abs(trendCal.grossProfit)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(trendCal.grossProfit)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                        {trendCal.grossProfit >= 0 ? (
                          <img src={ArrowUp} alt="arrow-up" height={55} />
                        ) : (
                          <img src={ArrowDown} alt="arrow-up" height={55} />
                        )}
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            {" "}
                            Operating Expense
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {trendCal.operatingExpense < 0
                              ? `(${Math.ceil(
                                  Math.abs(trendCal.operatingExpense)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(trendCal.operatingExpense)
                                ).toLocaleString()}`}
                          </p>
                        </div>

                        {trendCal.operatingExpense >= 0 ? (
                          <img src={ArrowUp} alt="arrow-up" height={55} />
                        ) : (
                          <img src={ArrowDown} alt="arrow-up" height={55} />
                        )}
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            {" "}
                            Net Profit
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {" "}
                            {currency_symbols[company.currency]}
                            {trendCal.netProfit < 0
                              ? `(${Math.ceil(
                                  Math.abs(trendCal.netProfit)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(trendCal.netProfit)
                                ).toLocaleString()}`}
                          </p>
                        </div>

                        {trendCal.netProfit >= 0 ? (
                          <img src={ArrowUp} alt="arrow-up" height={55} />
                        ) : (
                          <img src={ArrowDown} alt="arrow-up" height={55} />
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
                <Box style={{ height: 30, width: "100%" }} />
                <Box style={{ height: 30, width: "100%" }} />

                <Grid
                  style={{
                    backgroundColor: "#EBF8FC",
                    height: "max-content",
                    padding: "20px",
                    borderRadius: "20px",
                    // paddingBottom: "30px",
                  }}
                >
                  <Typography>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 16,
                      }}
                    >
                      <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 18,
                        }}
                      >
                        Analysis
                      </span>
                    </span>
                    <span style={{ fontSize: 16, color: "#606060" }}>
                      {trendCondition(trendCal)}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ height: 20, width: "100%" }} />
                  <Divider />
                  <Box style={{ height: 20, width: "100%" }} />
                  <Grid container xs={12}>
                    <Accordian
                      noteData={trendsNotes}
                      key1="trendsNotes"
                      selectedDate={fromDate}
                      selectedDate1={toSelectedDate}
                      notesRef={trendsNotesRef}
                      setIsGraphUpdate={setIsGraphUpdate}
                    />

                    <Box style={{ height: 20, width: "100%" }} />
                    <Divider />
                    <Box style={{ height: 20, width: "100%" }} />
                    <ActionLogTable
                      setReload={setReload}
                      actionLogs={homeRunActionLogs}
                      periodData={periodData}
                      selectedCompany={selectedCompany}
                      user={user}
                      type="Trends"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardWrapper>
            <Box style={{ height: 30, width: "100%" }} />
            <CardWrapper border="1px solid #E7E7E7">
              <Grid container xs={12}>
                <Box style={{ height: 30, width: "100%" }} />
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: 25,
                  }}
                >
                  Expense Control
                </span>
                <Box ref={exCntrlRef} style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      {yearsData ? (
                        <ExpenseControlChart
                          expenseControl={expenseControl}
                          range={range}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            {" "}
                            Gross Profit
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {expenseControlCal.grossProfit < 0
                              ? `(${Math.ceil(
                                  Math.abs(expenseControlCal.grossProfit)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(expenseControlCal.grossProfit)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                        {expenseControlCal.grossProfit >= 0 ? (
                          <img src={ArrowUp} alt="arrow-up" height={55} />
                        ) : (
                          <img src={ArrowDown} alt="arrow-up" height={55} />
                        )}
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Operating Expense
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {expenseControlCal.expenseControl < 0
                              ? `(${Math.ceil(
                                  Math.abs(expenseControlCal.expenseControl)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(expenseControlCal.expenseControl)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                        {expenseControlCal.expenseControl >= 0 ? (
                          <img src={ArrowUp} alt="arrow-up" height={55} />
                        ) : (
                          <img src={ArrowDown} alt="arrow-up" height={55} />
                        )}
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Result
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {expenseControlCal.result < 0
                              ? `(${Math.ceil(
                                  Math.abs(expenseControlCal.result)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(expenseControlCal.result)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                        {expenseControlCal.result >= 0 ? (
                          <img src={ArrowUp} alt="arrow-up" height={55} />
                        ) : (
                          <img src={ArrowDown} alt="arrow-up" height={55} />
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
                <Box style={{ height: 30, width: "100%" }} />
                <Box style={{ height: 30, width: "100%" }} />

                <Grid
                  xs={12}
                  style={{
                    backgroundColor: "#EBF8FC",
                    height: "max-content",
                    padding: "20px",
                    borderRadius: "20px",
                    // paddingBottom: "30px",
                  }}
                >
                  <Typography>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 16,
                      }}
                    >
                      <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 18,
                        }}
                      >
                        Analysis
                      </span>
                    </span>
                    <span style={{ fontSize: 16, color: "#606060" }}>
                      {expenseControlCondition(expenseControlCal)}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ height: 20, width: "100%" }} />
                <Divider />
                <Box style={{ height: 20, width: "100%" }} />
                <Grid container xs={12}>
                  <Accordian
                    noteData={expenseControlNotes}
                    key1="expenseControlNotes"
                    selectedDate={fromDate}
                    selectedDate1={toSelectedDate}
                    notesRef={exCntrlNotesRef}
                    setIsGraphUpdate={setIsGraphUpdate}
                  />
                  <Box style={{ height: 20, width: "100%" }} />
                  <Divider />
                  <Box style={{ height: 20, width: "100%" }} />
                  <ActionLogTable
                    setReload={setReload}
                    actionLogs={homeRunActionLogs}
                    periodData={periodData}
                    selectedCompany={selectedCompany}
                    user={user}
                    type="ExpenseControl"
                  />
                </Grid>
              </Grid>
            </CardWrapper>
            <Box style={{ height: 30, width: "100%" }} />
            <CardWrapper border="1px solid #E7E7E7">
              <Grid container xs={12}>
                <Box style={{ height: 30, width: "100%" }} />
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: 25,
                  }}
                >
                  Debt to Equity Ratio
                </span>
                <Box ref={debtEquityRef} style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      {yearsData ? (
                        <DeptToEquityRatioChart
                          deptToEquityRatio={deptToEquityRatio}
                          range={range}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Total Liabilities
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {deptToEquityRatioCal.liability < 0
                              ? `(${Math.ceil(
                                  Math.abs(deptToEquityRatioCal.liability)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(deptToEquityRatioCal.liability)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>

                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Total Equity
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {deptToEquityRatioCal.equity < 0
                              ? `(${Math.ceil(
                                  Math.abs(deptToEquityRatioCal.equity)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(deptToEquityRatioCal.equity)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Debt To Equity Ratio
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {deptToEquityRatioCal.ratio < 0
                              ? `(${Math.abs(
                                  deptToEquityRatioCal.ratio
                                ).toLocaleString()})`
                              : `${Math.abs(
                                  deptToEquityRatioCal.ratio
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box style={{ height: 30, width: "100%" }} />
                <Box style={{ height: 30, width: "100%" }} />
                <Grid
                  xs={12}
                  style={{
                    backgroundColor: "#EBF8FC",
                    height: "max-content",
                    padding: "20px",
                    borderRadius: "20px",
                    // paddingBottom: "30px",
                  }}
                >
                  <Typography>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 16,
                      }}
                    >
                      <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 18,
                        }}
                      >
                        Analysis
                      </span>
                    </span>
                    <span style={{ fontSize: 16, color: "#606060" }}>
                      {deptToEquityRatioCondition(deptToEquityRatioCal)}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ height: 20, width: "100%" }} />
                  <Divider />
                  <Box style={{ height: 20, width: "100%" }} />
                  <Grid container xs={12}>
                    <Accordian
                      noteData={debtEqRatioNotes}
                      key1="debtEqRatioNotes"
                      selectedDate={fromDate}
                      selectedDate1={toSelectedDate}
                      notesRef={debtEquityNotesRef}
                      setIsGraphUpdate={setIsGraphUpdate}
                    />
                    <Box style={{ height: 20, width: "100%" }} />
                    <Divider />
                    <Box style={{ height: 20, width: "100%" }} />
                    <ActionLogTable
                      setReload={setReload}
                      actionLogs={homeRunActionLogs}
                      periodData={periodData}
                      selectedCompany={selectedCompany}
                      user={user}
                      type="DebtToEquityRatio"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardWrapper>
            <Box style={{ height: 30, width: "100%" }} />
            <CardWrapper border="1px solid #E7E7E7">
              <Grid container xs={12}>
                <Box style={{ height: 30, width: "100%" }} />
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: 25,
                  }}
                >
                  EBITDA
                </span>
                <Box ref={ebitdaRef} style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      {yearsData ? (
                        <EBITDA ebitda={ebitda} range={range} />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            EBITDA
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {ebitdaCal.ebitdaVal < 0
                              ? `(${Math.ceil(
                                  Math.abs(ebitdaCal.ebitdaVal)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(ebitdaCal.ebitdaVal)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>

                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            LTD Capacity
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {ebitdaCal.ltdCapacity < 0
                              ? `(${Math.ceil(
                                  Math.abs(ebitdaCal.ltdCapacity)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(ebitdaCal.ltdCapacity)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Current LTD
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {ebitdaCal.currentLtd < 0
                              ? `(${Math.ceil(
                                  Math.abs(ebitdaCal.currentLtd)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(ebitdaCal.currentLtd)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Borrowing Power
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {ebitdaCal.borrowingPower < 0
                              ? `(${Math.ceil(
                                  Math.abs(ebitdaCal.borrowingPower)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(ebitdaCal.borrowingPower)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box style={{ height: 30, width: "100%" }} />
                <Box style={{ height: 30, width: "100%" }} />
                <Grid
                  xs={12}
                  style={{
                    backgroundColor: "#EBF8FC",
                    height: "max-content",
                    padding: "20px",
                    borderRadius: "20px",
                    // paddingBottom: "30px",
                  }}
                >
                  <Typography>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 16,
                      }}
                    >
                      <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 18,
                        }}
                      >
                        Analysis
                      </span>
                    </span>
                    <span style={{ fontSize: 16, color: "#606060" }}>
                      {ebitdaCondition(ebitdaCal)}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ height: 20, width: "100%" }} />
                  <Divider />
                  <Box style={{ height: 20, width: "100%" }} />
                  <Grid container xs={12}>
                    <Accordian
                      noteData={ebidtaNotes}
                      key1="ebidtaNotes"
                      selectedDate={fromDate}
                      selectedDate1={toSelectedDate}
                      notesRef={ebitdaNotesRef}
                      setIsGraphUpdate={setIsGraphUpdate}
                    />
                    <Box style={{ height: 20, width: "100%" }} />
                    <Divider />
                    <Box style={{ height: 20, width: "100%" }} />
                    <ActionLogTable
                      setReload={setReload}
                      actionLogs={homeRunActionLogs}
                      periodData={periodData}
                      selectedCompany={selectedCompany}
                      user={user}
                      type="EBITDA"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardWrapper>
            <Box style={{ height: 30, width: "100%" }} />
            <CardWrapper border="1px solid #E7E7E7">
              <Grid container xs={12}>
                <Box style={{ height: 30, width: "100%" }} />
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: 25,
                  }}
                >
                  Mis-Matched Financing
                </span>
                <Box ref={misMatchRef} style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      {yearsData ? (
                        <MisMatchedChart
                          misMatched={misMatched}
                          range={range}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Change In Gross Fixed Assets
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {misMatchedCal.grossFixedAssets < 0
                              ? `(${Math.ceil(
                                  Math.abs(misMatchedCal.grossFixedAssets)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(misMatchedCal.grossFixedAssets)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>

                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Change In Long Term Debt
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {misMatchedCal.lognTermDebt < 0
                              ? `(${Math.ceil(
                                  Math.abs(misMatchedCal.lognTermDebt)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(misMatchedCal.lognTermDebt)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Change In Retained Earnings
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {misMatchedCal.retainedEarnings < 0
                              ? `(${Math.ceil(
                                  Math.abs(misMatchedCal.retainedEarnings)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(misMatchedCal.retainedEarnings)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Asset Financing Gap
                          </p>
                          <p
                            style={
                              misMatchedCal.results > 0
                                ? {
                                    fontSize: 30,
                                    fontWeight: 600,
                                    color: "red",
                                  }
                                : { fontSize: 30, fontWeight: 600 }
                            }
                          >
                            {currency_symbols[company.currency]}
                            {misMatchedCal.results < 0
                              ? `(${Math.ceil(
                                  Math.abs(misMatchedCal.results)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(misMatchedCal.results)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box style={{ height: 30, width: "100%" }} />
                <Box style={{ height: 30, width: "100%" }} />
                <Grid
                  xs={12}
                  style={{
                    backgroundColor: "#EBF8FC",
                    height: "max-content",
                    padding: "20px",
                    borderRadius: "20px",
                    // paddingBottom: "30px",
                  }}
                >
                  <Typography>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 16,
                      }}
                    >
                      <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 18,
                        }}
                      >
                        Analysis
                      </span>
                    </span>
                    <span style={{ fontSize: 16, color: "#606060" }}>
                      {misMatchedCondition(misMatchedCal)}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ height: 20, width: "100%" }} />
                  <Divider />
                  <Box style={{ height: 20, width: "100%" }} />
                  <Grid container xs={12}>
                    <Accordian
                      noteData={misMatchNotes}
                      key1="misMatchNotes"
                      selectedDate={fromDate}
                      selectedDate1={toSelectedDate}
                      notesRef={misMatchNotesRef}
                      setIsGraphUpdate={setIsGraphUpdate}
                    />
                    <Box style={{ height: 20, width: "100%" }} />
                    <Divider />
                    <Box style={{ height: 20, width: "100%" }} />
                    <ActionLogTable
                      setReload={setReload}
                      actionLogs={homeRunActionLogs}
                      periodData={periodData}
                      selectedCompany={selectedCompany}
                      user={user}
                      type="MisMatchedFinanc"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardWrapper>
            <Box style={{ height: 30, width: "100%" }} />
            <CardWrapper border="1px solid #E7E7E7">
              <Grid container xs={12}>
                <Box style={{ height: 30, width: "100%" }} />
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: 25,
                  }}
                >
                  Cash Flow Activity Pattern
                </span>
                <Box ref={cashFlowActRef} style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      {yearsData ? (
                        <CashFlowActivityPatternChart
                          cashFlow={cashFlow}
                          range={range}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Operating Activities
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {cashFlowCal.operatingActivities < 0
                              ? `(${Math.ceil(
                                  Math.abs(cashFlowCal.operatingActivities)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(cashFlowCal.operatingActivities)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                        {cashFlowCal.operatingActivities > -1 ? (
                          <img src={Postive} alt="arrow-up" height={55} />
                        ) : (
                          <img src={Negative} alt="arrow-up" height={55} />
                        )}
                      </div>

                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Investing Activities
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {cashFlowCal.investingActivities < 0
                              ? `(${Math.ceil(
                                  Math.abs(cashFlowCal.investingActivities)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(cashFlowCal.investingActivities)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                        {cashFlowCal.investingActivities > -1 ? (
                          <img src={Postive} alt="arrow-up" height={55} />
                        ) : (
                          <img src={Negative} alt="arrow-up" height={55} />
                        )}
                      </div>
                      <div style={{ marginTop: "10%" }} className="cal-area">
                        <div>
                          <p style={{ fontSize: 16, marginBottom: 20 }}>
                            Financing Activities
                          </p>
                          <p style={{ fontSize: 30, fontWeight: 600 }}>
                            {currency_symbols[company.currency]}
                            {cashFlowCal.financingActivities < 0
                              ? `(${Math.ceil(
                                  Math.abs(cashFlowCal.financingActivities)
                                ).toLocaleString()})`
                              : `${Math.ceil(
                                  Math.abs(cashFlowCal.financingActivities)
                                ).toLocaleString()}`}
                          </p>
                        </div>
                        {cashFlowCal.financingActivities > -1 ? (
                          <img src={Postive} alt="arrow-up" height={55} />
                        ) : (
                          <img src={Negative} alt="arrow-up" height={55} />
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
                <Box style={{ height: 30, width: "100%" }} />
                <Box style={{ height: 30, width: "100%" }} />
                <Grid
                  xs={12}
                  style={{
                    backgroundColor: "#EBF8FC",
                    height: "max-content",
                    padding: "20px",
                    borderRadius: "20px",
                    // paddingBottom: "30px",
                  }}
                >
                  <Typography>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 16,
                      }}
                    >
                      <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 18,
                        }}
                      >
                        Analysis
                      </span>
                    </span>
                    <span style={{ fontSize: 16, color: "#606060" }}>
                      {cashFlowCondition(cashFlowCal)}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ height: 20, width: "100%" }} />
                  <Divider />
                  <Box style={{ height: 20, width: "100%" }} />
                  <Grid container xs={12}>
                    <Accordian
                      noteData={cashFlowActvtyNotes}
                      key1="cashFlowActvtyNotes"
                      selectedDate={fromDate}
                      notesRef={cashFlowActNotesRef}
                      selectedDate1={toSelectedDate}
                      setIsGraphUpdate={setIsGraphUpdate}
                    />
                    <Box style={{ height: 20, width: "100%" }} />
                    <Divider />
                    <Box style={{ height: 20, width: "100%" }} />
                    <ActionLogTable
                      setReload={setReload}
                      actionLogs={homeRunActionLogs}
                      periodData={periodData}
                      selectedCompany={selectedCompany}
                      user={user}
                      type="CashFlowActivityPattern"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardWrapper>
          </>
          {/* ) : (
            <CardWrapper border="1px solid #E7E7E7">
              <Typography style={{ color: "#bfbfbf" }}>
                Please Select Period
              </Typography>
            </CardWrapper>
          )} */}
        </CardWrapper>
      </Layout>
    </React.Fragment>
  );
};
export default HomeRun;
