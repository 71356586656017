import { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  ACTION_LOG_CATEGORIES,
  ACTION_LOG_STATUS,
  ACTION_LOG_OUTCOME,
} from "../../utils/constants";
import { addActionLog, editActionLog } from "../../redux/actions/actionLog";
import { useDispatch } from "react-redux";
import { clearSnack } from "../../utils/utilFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import { getActionLogByCompany } from "../../redux/actions/actionLog";

const AddActionModal = ({
  open,
  setOpen,
  periodData,
  selectedCompany,
  user,
  type,
  setLogData,
  logData,
  selectedActionLogId,
  setSelectedActionLogId,
  setReload,
}) => {
  console.log("periodData --<", periodData);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  console.log("logData in add action modal -->", logData);

  const handleChange = (e) => {
    setLogData({ ...logData, [e.target.name]: e.target.value });
  };

  const handleEditActionLog = async () => {
    setLoading(true);
    console.log("logData -->", logData);
    if (
      !logData.actionDescription ||
      !logData.expectedResult ||
      !logData.dateOfAction ||
      !logData.category
      // ||
      // !logData.status
    ) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          message: "Please fill all the fields",
          snackType: "error",
        },
      });
      clearSnack(dispatch);
      setLoading(false);
      return;
    }
    const payload = {
      description: logData.actionDescription,
      expectedResult: logData.expectedResult,
      date: logData.dateOfAction,
      category: logData.category,
      // status: logData.status,
      // outcome: logData.outcome,
    };
    const response = await dispatch(
      editActionLog(selectedActionLogId, payload)
    );
    dispatch(
      getActionLogByCompany({
        companyId: selectedCompany._id,
        periodId: periodData?._id,
        userId: user._id,
        type,
      })
    );
    if (response) {
      setLogData();
      setSelectedActionLogId();
      setOpen(false);
      setReload((prev) => !prev);
    }
    setLoading(false);
  };

  const handleAddAction = async () => {
    setLoading(true);
    console.log("logData -->", logData);
    if (
      !logData.actionDescription ||
      !logData.expectedResult ||
      !logData.dateOfAction ||
      !logData.category
      // ||
      // !logData.status
    ) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          message: "Please fill all the fields",
          snackType: "error",
        },
      });
      clearSnack(dispatch);
      setLoading(false);
      return;
    }

    console.log("periodData -->", periodData);

    const response = await dispatch(
      addActionLog({
        description: logData.actionDescription,
        expectedResult: logData.expectedResult,
        date: logData.dateOfAction,
        category: logData.category,
        companyId: selectedCompany._id,
        periodId: periodData?._id,
        userId: user._id,
        // status: logData.status,
        type,
        // outcome: logData.outcome,
      })
    );

    dispatch(
      getActionLogByCompany({
        companyId: selectedCompany._id,
        periodId: periodData?._id,
        userId: user._id,
        type,
      })
    );

    console.log("response -->", response);
    if (response) {
      setReload((prev) => !prev);
      setOpen(false);
      setLogData();
    }
    setLoading(false);
  };

  const handleClose = () => setOpen(false);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#efefef",
          boxShadow: 24,
          p: 4,
          borderRadius: "10px",
          minWidth: "600px",
        }}
      >
        <Grid
          container
          xs={12}
          style={{
            padding: "30px 25px",
            display: "grid",
            gap: "20px",
          }}
        >
          <Typography
            style={{ fontSize: 22, fontWeight: 600, textAlign: "center" }}
          >
            Log a new action
          </Typography>

          {/* <Grid
            style={{
              maxHeight: "400px",
              overflowY: "scroll",
              overflowX: "-moz-hidden-unscrollable",
              padding: "20px",
            }}
          > */}
          <Box>
            <label>
              <b>Action Description:</b>
            </label>
            <input
              className="customize-input1"
              placeholder="Action Description"
              color="white"
              name="actionDescription"
              type="text"
              onChange={handleChange}
              value={logData?.actionDescription}
            />
          </Box>
          <Box>
            <label>
              <b>Expected Result:</b>
            </label>
            <input
              className="customize-input1"
              placeholder="Expected Result"
              color="white"
              name="expectedResult"
              type="text"
              onChange={handleChange}
              value={logData?.expectedResult}
            />
          </Box>
          <Box>
            <label>
              <b>Date of Action:</b>
            </label>
            <div
              style={{
                fontFamily: "arial",
                color: "#03111e",
                backgroundColor: "transparent",
                width: "100%",
                height: "50px",
                marginTop: "2%",
                borderRadius: "20px",
                border: "1px solid",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="date"
                style={{
                  width: "90%",
                  height: "50px",
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                }}
                onChange={handleChange}
                name="dateOfAction"
                className="hide-outline"
                value={logData?.dateOfAction}
              />
            </div>
          </Box>
          <Box>
            <label>
              <b>Category:</b>
            </label>
            <FormControl className="industry-form">
              <Select
                label="Industry"
                sx={{ border: "1px solid", borderRadius: "22px" }}
                value={logData?.category}
                onChange={handleChange}
                name="category"
              >
                {Object.keys(ACTION_LOG_CATEGORIES).map((action) => (
                  <MenuItem value={action}>
                    {ACTION_LOG_CATEGORIES[action].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* <Box style={{ marginTop: "20px" }}>
              <label>
                <b>Status:</b>
              </label>
              <FormControl className="industry-form">
                <Select
                  label="Industry"
                  sx={{ border: "1px solid", borderRadius: "22px" }}
                  value={logData?.status}
                  onChange={handleChange}
                  name="status"
                >
                  {ACTION_LOG_STATUS.map((status) => (
                    <MenuItem value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box> */}

          {/* <Box style={{ marginTop: "20px" }}>
              <label>
                <b>Outcome:</b>
              </label>
              <FormControl className="industry-form">
                <Select
                  label="Industry"
                  sx={{ border: "1px solid", borderRadius: "22px" }}
                  value={logData?.outcome}
                  onChange={handleChange}
                  name="outcome"
                >
                  {ACTION_LOG_OUTCOME.map((outcome) => (
                    <MenuItem value={outcome}>{outcome}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box> */}
          {/* </Grid> */}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={loading}
              variant="contained"
              style={{
                backgroundColor: "#201b5b",
                textTransform: "capitalize",
                height: "50px",
                borderRadius: "40px",
                color: "white",
                width: "50%",
              }}
              onClick={
                selectedActionLogId ? handleEditActionLog : handleAddAction
              }
            >
              {selectedActionLogId ? "Edit" : "Submit"}{" "}
              {loading && <CircularProgress paddingLeft="10px" size={30} />}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddActionModal;
