import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import axios from "axios";
import { Layout } from "../../layout";
import { CardWrapper } from "../../components";
import formateDate from "../../utils/formateDate";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import {
  Grid,
  Typography,
  Button,
  Tabs,
  Tab,
  Modal,
  Box,
  Divider,
  Select,
  FormControl,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Chip,
  Stack,
  InputAdornment,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CurrencyInput from "./CurrencyInput.jsx";
import NumberInput from "./NumberInput.jsx";
import * as yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Edit from "../../images/edit.png";
import Delete from "../../images/delete.png";
import dayjs from "dayjs";
import {
  fetchCompanyDetails,
  updateCompanyDetails,
  addCompanyDetails,
  checkPeriodDuplicate,
} from "../../redux/actions/companyDetails.js";
import { deletePeriod } from "../../redux/actions/companyDetails";
import SnackBar from "../../components/snackBar";
import { clientCheck, removeComma } from "../../utils/utilFunctions.js";
import PropTypes from "prop-types";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { handlInfo } from "../../utils/utilFunctions.js";
import quickbooks from "../../assets/quickbooks.png";
import quickbooks1 from "../../assets/quickbooks1.png";
import qbConnector from "../../assets/qb-connector.png";
import { FaArrowLeft } from "react-icons/fa6";
import {
  PERIOD_DURATION,
  OWNER_GOAL,
  INCOME_STATEMENT,
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  HIDDEN_CASH_OWNER_GOAL,
  HIDDEN_CASH_INCOME_STATEMENT,
  HIDDEN_CASH_BALANCE_SHEET,
  FAST_MONEY_INCOME_STATEMENT,
  FAST_MONEY_BALANCE_SHEET,
  HOME_RUN_INCOME_STATEMENT,
  HOME_RUN_BALANCE_SHEET,
  HOME_RUN_CASH_FLOW_STATEMENT,
  FINANCIAL_DATA_INCOME_STATEMENT,
  FINANCIAL_DATA_BALANCE_SHEET,
  FINANCIAL_DATA_CASH_FLOW_STATEMENT,
  SVS_INCOME_STATEMENT,
  SVS_BALANCE_SHEET,
  FINANCIAL_DATA_OWNER_GOAL,
} from "../../utils/constants.js";
import { BASE_URL } from "../../utils/config";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";
import currency_symbols from "../../utils/currency";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  border: "none",
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    order: -1,
    marginRight: theme.spacing(1),
  },
}));

export const OPEN_SNACK = "OPEN_SNACK";
export const CLEAR = "CLEAR";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "#efefef",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const FinancialData = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isTab = searchParams.get("tab");

  const {
    periods,
    industryAverage,
    financialData,
    company,
    companyDetailsLoading,
  } = useSelector((state) => state.companyDetailReducer);
  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const { user } = useSelector((state) => state.userReducer);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deletPeriodModelOpen, setDeletPeriodModelOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openRefresh, setOpenRefresh] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [periodData, setPeriodData] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [dateType, setDateType] = useState("");
  const [periodName, setPeriodName] = useState("");
  const [connected, setConnected] = useState("");
  const [selectedPeriods, setSelectedPeriods] = useState([]);
  const selectedPeriodsRef = useRef(selectedPeriods);
  const [financialDataId, setFinancialDataId] = useState("");
  const [manualFields, setManualFields] = useState({});
  const [value, setValue] = useState(isTab ? parseInt(isTab) : 0);
  const initialMapValue = {
    amortExpense: [],
    depExpense: [],
    interestExpense: [],
    taxesPaid: [],
    avgPayrollExpense: [],
    daysInPayroll: "",
    familyExcess: "",
    capx: "",
    debtPayments: "",
  };
  const [mapping, setMapping] = useState(initialMapValue);
  const [preDefinedOwnerGaolVals, setPreDefinedOwnerGaolVals] = useState({});
  const [userInput, setUserInput] = useState(false);
  const [qboInput, setQboInput] = useState(false);
  const [trialBalanceData, setTrialBalanceData] = useState([]);
  const [includeRollingYear, setIncludeRollingYear] = useState(false);
  const [refreshConnect, setRefreshConnect] = useState(false);
  const refreshConnectRef = useRef(refreshConnect);
  const [mappingData, setMappingData] = useState(false);
  useEffect(() => {
    if (financialData.length) {
      const periodWithOwnerGoalVals = financialData.find(
        (data) =>
          data.ownerGoal.accountsPayableTurnoverRate ||
          data.ownerGoal.accountsReceivableTurnoverRate ||
          data.ownerGoal.grossProfitePercentage ||
          data.ownerGoal.inventoryTurnoverRate ||
          data.ownerGoal.netProfitePercentage ||
          data.ownerGoal.salesToAssets
      );
      if (periodWithOwnerGoalVals) {
        setPreDefinedOwnerGaolVals(periodWithOwnerGoalVals.ownerGoal);
      }
      let value = financialData.find((x) => x.year === selectedPeriod);
      if (value?.manual) {
        setManualFields(value.manual);
      } else {
        setManualFields({});
      }
      if (value) {
        setPeriodData(value);
        setDateType(value.dateType);
        setSelectedDate(value.year);
        setPeriodName(value.periodName);
        setFinancialDataId(value._id);
      } else {
        setPeriodData({});
      }
    }
  }, [financialData]);

  const validationSchema = yup.object({
    salesToAssets: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    grossProfitePercentage: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    netProfitePercentage: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    inventoryTurnoverRate: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    accountsReceivableTurnoverRate: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    accountsPayableTurnoverRate: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    ebidta: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    roa: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    roi: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    currentRatio: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    quickRatio: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    debtToEquityRatio: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    dcoh: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    salesinUSD: yup.lazy(() => {
      if (value !== 4) return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    totalAssetsinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    grossFixedAssets: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    grossProfiteinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    netProfiteBeforeTaxinUSD: yup.lazy(() => {
      if (
        value === 0 ||
        value === 1 ||
        value === 3 ||
        value === 4 ||
        value === 5
      )
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    operatingActivities: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    investingActivities: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    financingActivities: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    operatingExpense: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 1 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    taxesPaid: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    interestExpense: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    depExpense: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    amortExpense: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    costOfGoodsSoldinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    inventoryinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    accountReceivableinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    accountPayableinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    retainedEarnings: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    totalEquity: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    longTermDebt: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    debtPayments: yup.lazy(() => {
      if (value === 0 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    totalLiabilities: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    avgPayrollExpense: yup.lazy((value) => {
      if ((company?.isService && value === 2) || value === 5) {
        return yup.string().required("All Fields are required");
      }
      return yup.string().nullable().optional();
    }),
    daysInPayroll: yup.lazy((value) => {
      if ((company?.isService && value === 2) || value === 5) {
        return yup.string().required("All Fields are required");
      }
      return yup.string().nullable().optional();
    }),
    currenAssets: yup.lazy(() => {
      if (value === 0 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    cash: yup.lazy(() => {
      if (value === 0 || value === 5 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    currenLiabilities: yup.lazy(() => {
      if (value === 0 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    capx: yup.lazy(() => {
      if (value === 0 || value === 4)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    familyExcess: yup.lazy(() => {
      if (value === 0 || value === 4)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
  });

  const initialValues = {
    // owner goal values condition description: if no value is assigned it should show the value of any recent owner goal and if new period is selected it should show any recent owner goal

    salesToAssets: Object.keys(periodData).length
      ? periodData.ownerGoal.salesToAssets
        ? periodData.ownerGoal.salesToAssets
        : preDefinedOwnerGaolVals?.salesToAssets
        ? preDefinedOwnerGaolVals?.salesToAssets
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.salesToAssets
        ? preDefinedOwnerGaolVals?.salesToAssets
        : ""
      : "",
    grossProfitePercentage: Object.keys(periodData).length
      ? periodData.ownerGoal.grossProfitePercentage
        ? periodData.ownerGoal.grossProfitePercentage
        : preDefinedOwnerGaolVals?.grossProfitePercentage
        ? preDefinedOwnerGaolVals?.grossProfitePercentage
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.grossProfitePercentage
        ? preDefinedOwnerGaolVals?.grossProfitePercentage
        : ""
      : "",

    netProfitePercentage: Object.keys(periodData).length
      ? periodData.ownerGoal.netProfitePercentage
        ? periodData.ownerGoal.netProfitePercentage
        : preDefinedOwnerGaolVals?.netProfitePercentage
        ? preDefinedOwnerGaolVals?.netProfitePercentage
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.netProfitePercentage
        ? preDefinedOwnerGaolVals?.netProfitePercentage
        : ""
      : "",
    // inventoryTurnoverRate: Object.keys(periodData).length
    //   ? periodData.ownerGoal.inventoryTurnoverRate &&
    //     parseInt(periodData.ownerGoal.inventoryTurnoverRate)
    //     ? (365 / periodData.ownerGoal.inventoryTurnoverRate).toFixed(0)
    //     : periodData.ownerGoal.inventoryTurnoverRate
    //   : "",

    inventoryTurnoverRate: Object.keys(periodData).length
      ? periodData.ownerGoal.inventoryTurnoverRate
        ? parseInt(periodData.ownerGoal.inventoryTurnoverRate)
        : // ? (
          //     365 / parseInt(periodData.ownerGoal.inventoryTurnoverRate)
          //   ).toFixed(0)
          // : periodData.ownerGoal.inventoryTurnoverRate
          preDefinedOwnerGaolVals?.inventoryTurnoverRate
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.inventoryTurnoverRate
        ? preDefinedOwnerGaolVals?.inventoryTurnoverRate
        : ""
      : "",

    accountsReceivableTurnoverRate: Object.keys(periodData).length
      ? periodData.ownerGoal.accountsReceivableTurnoverRate
        ? parseInt(periodData.ownerGoal.accountsReceivableTurnoverRate)
        : // ? (
        //     365 /
        //     parseInt(periodData.ownerGoal.accountsReceivableTurnoverRate)
        //   ).toFixed(0)
        // : periodData.ownerGoal.accountsReceivableTurnoverRate
        preDefinedOwnerGaolVals?.accountsReceivableTurnoverRate
        ? parseInt(preDefinedOwnerGaolVals.accountsReceivableTurnoverRate)
          ? (
              365 /
              parseInt(preDefinedOwnerGaolVals.accountsReceivableTurnoverRate)
            ).toFixed(0)
          : preDefinedOwnerGaolVals.accountsReceivableTurnoverRate
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.accountsReceivableTurnoverRate
        ? parseInt(preDefinedOwnerGaolVals.accountsReceivableTurnoverRate)
          ? (
              365 /
              parseInt(preDefinedOwnerGaolVals.accountsReceivableTurnoverRate)
            ).toFixed(0)
          : preDefinedOwnerGaolVals.accountsReceivableTurnoverRate
        : ""
      : "",

    accountsPayableTurnoverRate: Object.keys(periodData).length
      ? periodData.ownerGoal.accountsPayableTurnoverRate
        ? parseInt(periodData.ownerGoal.accountsPayableTurnoverRate)
        : // ? (
        //     365 / parseInt(periodData.ownerGoal.accountsPayableTurnoverRate)
        //   ).toFixed(0)
        // : periodData.ownerGoal.accountsPayableTurnoverRate
        preDefinedOwnerGaolVals?.accountsPayableTurnoverRate
        ? parseInt(preDefinedOwnerGaolVals.accountsPayableTurnoverRate)
          ? (
              365 /
              parseInt(preDefinedOwnerGaolVals.accountsPayableTurnoverRate)
            ).toFixed(0)
          : preDefinedOwnerGaolVals.accountsPayableTurnoverRate
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.accountsPayableTurnoverRate
        ? parseInt(preDefinedOwnerGaolVals.accountsPayableTurnoverRate)
          ? (
              365 /
              parseInt(preDefinedOwnerGaolVals.accountsPayableTurnoverRate)
            ).toFixed(0)
          : preDefinedOwnerGaolVals.accountsPayableTurnoverRate
        : ""
      : "",

    ebidta: Object.keys(periodData).length
      ? periodData.ownerGoal.ebidta
        ? periodData.ownerGoal.ebidta
        : preDefinedOwnerGaolVals?.ebidta
        ? preDefinedOwnerGaolVals?.ebidta
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.ebidta
        ? preDefinedOwnerGaolVals?.ebidta
        : ""
      : "",

    roa: Object.keys(periodData).length
      ? periodData.ownerGoal.roa
        ? periodData.ownerGoal.roa
        : preDefinedOwnerGaolVals?.roa
        ? preDefinedOwnerGaolVals?.roa
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.roa
        ? preDefinedOwnerGaolVals?.roa
        : ""
      : "",

    roi: Object.keys(periodData).length
      ? periodData.ownerGoal.roi
        ? periodData.ownerGoal.roi
        : preDefinedOwnerGaolVals?.roi
        ? preDefinedOwnerGaolVals?.roi
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.roi
        ? preDefinedOwnerGaolVals?.roi
        : ""
      : "",

    currentRatio: Object.keys(periodData).length
      ? periodData.ownerGoal.currentRatio
        ? periodData.ownerGoal.currentRatio
        : preDefinedOwnerGaolVals?.currentRatio
        ? preDefinedOwnerGaolVals?.currentRatio
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.currentRatio
        ? preDefinedOwnerGaolVals?.currentRatio
        : ""
      : "",

    quickRatio: Object.keys(periodData).length
      ? periodData.ownerGoal.quickRatio
        ? periodData.ownerGoal.quickRatio
        : preDefinedOwnerGaolVals?.quickRatio
        ? preDefinedOwnerGaolVals?.quickRatio
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.quickRatio
        ? preDefinedOwnerGaolVals?.quickRatio
        : ""
      : "",

    debtToEquityRatio: Object.keys(periodData).length
      ? periodData.ownerGoal.debtToEquityRatio
        ? periodData.ownerGoal.debtToEquityRatio
        : preDefinedOwnerGaolVals?.debtToEquityRatio
        ? preDefinedOwnerGaolVals?.debtToEquityRatio
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.debtToEquityRatio
        ? preDefinedOwnerGaolVals?.debtToEquityRatio
        : ""
      : "",

    dcoh: Object.keys(periodData).length
      ? periodData.ownerGoal.dcoh
        ? periodData.ownerGoal.dcoh
        : preDefinedOwnerGaolVals?.dcoh
        ? preDefinedOwnerGaolVals?.dcoh
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.dcoh
        ? preDefinedOwnerGaolVals?.dcoh
        : ""
      : "",
    salesinUSD: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.salesinUSD))
      : "",

    totalAssetsinUSD: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.totalAssetsinUSD))
      : "",
    grossFixedAssets: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.grossFixedAssets ?? ""))
      : "",
    grossProfiteinUSD: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.grossProfiteinUSD))
      : "",
    netProfiteBeforeTaxinUSD: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.netProfiteBeforeTaxinUSD))
      : "",
    operatingActivities: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.cashFlow?.operatingActivities ?? ""))
      : "",
    investingActivities: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.cashFlow?.investingActivities ?? ""))
      : "",
    financingActivities: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.cashFlow?.financingActivities ?? ""))
      : "",
    operatingExpense: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.operatingExpense))
      : "",
    taxesPaid: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.taxesPaid))
      : "",
    interestExpense: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.interestExpense))
      : "",
    depExpense: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.depExpense))
      : "",
    amortExpense: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.amortExpense ?? ""))
      : "",
    costOfGoodsSoldinUSD: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.costOfGoodsSoldinUSD))
      : "",
    inventoryinUSD: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.inventoryinUSD ?? ""))
      : "",
    capx: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.capx ?? ""))
      : "",
    familyExcess: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.familyExcess ?? ""))
      : "",
    accountReceivableinUSD: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.accountReceivableinUSD ?? ""))
      : "",
    accountPayableinUSD: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.accountPayableinUSD ?? ""))
      : "",
    retainedEarnings: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.retainedEarnings ?? ""))
      : "",
    totalEquity: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.totalEquity ?? ""))
      : "",
    longTermDebt: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.longTermDebt ?? ""))
      : "",
    debtPayments: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.debtPayments ?? ""))
      : "",
    totalLiabilities: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.totalLiabilities ?? ""))
      : "",
    avgPayrollExpense: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.avgPayrollExpense))
      : "",
    daysInPayroll: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.incomeStatement.daysInPayroll ?? ""))
      : "",
    currenAssets: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.currenAssets ?? ""))
      : "",
    cash: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.cash ?? ""))
      : "",
    currenLiabilities: Object.keys(periodData).length
      ? Math.abs(parseInt(periodData.balanceSheet.currenLiabilities ?? ""))
      : "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,

    onSubmit: async (values, errors) => {
      if (!clientCheck(user, dispatch)) {
        return;
      }
      if (!periodName) {
        dispatch({
          type: OPEN_SNACK,
          payload: {
            snackType: "error",
            message: "Please select period",
          },
        });
        setTimeout(() => {
          dispatch({
            type: CLEAR,
          });
        }, 3000);
        return;
      }

      const {
        salesToAssets,
        grossProfitePercentage,
        netProfitePercentage,
        inventoryTurnoverRate,
        accountsReceivableTurnoverRate,
        salesinUSD,
        accountsPayableTurnoverRate,
        ebidta,
        roa,
        roi,
        currentRatio,
        quickRatio,
        debtToEquityRatio,
        dcoh,
        grossProfiteinUSD,
        netProfiteBeforeTaxinUSD,
        operatingExpense,
        taxesPaid,
        interestExpense,
        depExpense,
        amortExpense,
        costOfGoodsSoldinUSD,
        avgPayrollExpense,
        daysInPayroll,
        inventoryinUSD,
        totalAssetsinUSD,
        grossFixedAssets,
        accountReceivableinUSD,
        accountPayableinUSD,
        retainedEarnings,
        totalEquity,
        longTermDebt,
        debtPayments,
        totalLiabilities,
        currenLiabilities,
        currenAssets,
        cash,
        operatingActivities,
        investingActivities,
        financingActivities,
        familyExcess,
        capx,
      } = values;

      const inputDate = new Date(selectedDate);
      const options = { day: "numeric", month: "long", year: "numeric" };
      const outputDateStr = inputDate.toLocaleDateString("en-US", options);

      const checkAndFormate = (val) => {
        // salesToAssets ? salesToAssets.toFixed(2) : "",
        if (val === 0 || val) {
          if (typeof val !== "string") {
            return val.toFixed(2);
          } else {
            return parseInt(val).toFixed(2);
          }
        }
        return val;
      };

      const payload = {
        ownerGoal: {
          salesToAssets: checkAndFormate(salesToAssets),
          grossProfitePercentage: checkAndFormate(grossProfitePercentage),
          netProfitePercentage: checkAndFormate(netProfitePercentage),

          // I was not sure why the 365 / turn so I commented it out (for all turnovers)
          // inventoryTurnoverRate: parseInt(inventoryTurnoverRate)
          //   ? Math.ceil(365 / checkAndFormate(inventoryTurnoverRate))
          //   : inventoryTurnoverRate,
          inventoryTurnoverRate: parseInt(inventoryTurnoverRate),
          // accountsReceivableTurnoverRate: parseInt(
          //   accountsReceivableTurnoverRate
          // )
          //   ? Math.ceil(365 / checkAndFormate(accountsReceivableTurnoverRate))
          //   : accountsReceivableTurnoverRate,
          accountsReceivableTurnoverRate: parseInt(
            accountsReceivableTurnoverRate
          ),
          // accountsPayableTurnoverRate: parseInt(accountsPayableTurnoverRate)
          //   ? Math.ceil(365 / checkAndFormate(accountsPayableTurnoverRate))
          //   : accountsPayableTurnoverRate,
          accountsPayableTurnoverRate: parseInt(accountsPayableTurnoverRate),
          ebidta: checkAndFormate(ebidta),
          roa: checkAndFormate(roa),
          roi: checkAndFormate(roi),
          currentRatio: checkAndFormate(currentRatio),
          quickRatio: checkAndFormate(quickRatio),
          debtToEquityRatio: checkAndFormate(debtToEquityRatio),
          dcoh: checkAndFormate(dcoh),
        },
        incomeStatement: {
          salesinUSD: removeComma(salesinUSD),
          grossProfiteinUSD: removeComma(grossProfiteinUSD),
          netProfiteBeforeTaxinUSD: removeComma(netProfiteBeforeTaxinUSD),
          operatingExpense: removeComma(operatingExpense),
          taxesPaid: removeComma(taxesPaid),
          interestExpense: removeComma(interestExpense),
          depExpense: removeComma(depExpense),
          amortExpense: removeComma(amortExpense),
          costOfGoodsSoldinUSD: removeComma(costOfGoodsSoldinUSD),
          avgPayrollExpense: removeComma(avgPayrollExpense),
          daysInPayroll: removeComma(daysInPayroll),
          familyExcess: removeComma(familyExcess),
        },
        cashFlow: {
          operatingActivities: removeComma(operatingActivities),
          investingActivities: removeComma(investingActivities),
          financingActivities: removeComma(financingActivities),
        },
        balanceSheet: {
          inventoryinUSD: removeComma(inventoryinUSD),
          totalAssetsinUSD: removeComma(totalAssetsinUSD),
          grossFixedAssets: removeComma(grossFixedAssets),
          accountReceivableinUSD: removeComma(accountReceivableinUSD),
          accountPayableinUSD: removeComma(accountPayableinUSD),
          retainedEarnings: removeComma(retainedEarnings),
          totalEquity: removeComma(totalEquity),
          longTermDebt: removeComma(longTermDebt),
          debtPayments: removeComma(debtPayments),
          totalLiabilities: removeComma(totalLiabilities),
          currenAssets: removeComma(currenAssets),
          cash: removeComma(cash),
          currenLiabilities: removeComma(currenLiabilities),
          capx: removeComma(capx),
        },

        companyId: company._id,
        year: outputDateStr,
        NAICScode: company.NAICScode,
        periodName: periodName,
        dateType: dateType,
        manual: manualFields,
      };

      const payload2 = {
        companyId: company._id,
      };
      await dispatch(addCompanyDetails(payload));
      await dispatch(fetchCompanyDetails(payload2));
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDateChange = (event) => {
    setSelectedPeriod(event.target.value);

    let value = financialData.find((x) => x.year === event.target.value);
    if (value?.manual) {
      setManualFields(value.manual);
    } else {
      setManualFields({});
    }
    if (value) {
      setPeriodData(value);
      setDateType(value.dateType);
      setSelectedDate(value.year);
      setPeriodName(value.periodName);
      setFinancialDataId(value._id);
    } else {
      setPeriodData({});
    }
  };

  const handleOpen = () => setOpen(true);
  const handleRefreshOpen = () => setOpenRefresh(true);
  const handleClose = () => {
    setOpen(false);
    setUserInput(false);
    setQboInput(false);
    setMapping(initialMapValue);
  };
  const handleRefreshClose = () => {
    setOpenRefresh(false);
    setSelectedPeriods([]);
  };
  const handleMappingClose = () => {
    setMappingData(false);
  };

  const handleDeletPeriodModelClose = () => setDeletPeriodModelOpen(false);

  const handleEditDate = (date) => {
    let formattedDate = new Date(date);
    setSelectedDate(formattedDate);
    setIsEdit(true);
    handleOpen();
  };

  const handleDeleteDate = async () => {
    const payload = {
      hiddenCashId: periodData._id,
    };
    const payload2 = {
      companyId: company._id,
    };
    await dispatch(deletePeriod(payload));
    await dispatch(fetchCompanyDetails(payload2));
    handleDeletPeriodModelClose();
  };

  const handleEditRecord = () => {
    const payload = {
      hiddenCashId: financialDataId,
      periodName: periodName,
      dateType: dateType,
      periodDate: selectedDate,
      companyId: company._id,
    };

    dispatch(updateCompanyDetails(payload));
    const payload2 = {
      companyId: company._id,
    };
    dispatch(fetchCompanyDetails(payload2));
  };

  const handleSubmitPeriod = async () => {
    if (!selectedDate || !periodName || !dateType) {
      dispatch({
        type: OPEN_SNACK,
        payload: {
          snackType: "error",
          message: "Please enter all fields",
        },
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 3000);
      return;
    }

    if (isEdit) {
      handleEditRecord();
    }

    const payload = {
      year: selectedDate,
      periodName,
      dateType,
      companyId: company._id,
    };

    const respo = await dispatch(checkPeriodDuplicate(payload));

    if (respo) {
      const newPeriods = [
        ...periods,
        {
          date: formateDate(selectedDate),
          dateType,
          periodName,
        },
      ];

      dispatch({
        type: "ASSIGN_PERIODS",
        payload: {
          data: newPeriods,
        },
      });

      formik.resetForm();
      setSelectedPeriod();
      handleClose();

      setSelectedPeriod(formateDate(selectedDate));
    }
  };

  const handleClassForInputs = () => "disabled_tabs_content_input";
  // selectedPeriod ? "tabs_content_input" : "disabled_tabs_content_input";

  const handleIndustryAvg = (industryAverage, item) => {
    return parseInt(item.turnover)
      ? (365 / industryAverage[item.industryAvg]).toFixed(0)
      : industryAverage[item.industryAvg];
  };

  const generateRandomLightColor = () => {
    return `#b7959b`;
  };

  const CompanyLogo = ({ companyName }) => {
    // Get the first letter of the company name
    const firstLetter = companyName ? companyName.charAt(0).toUpperCase() : "";

    // Style for the red circle
    const circleStyle = {
      width: "50px", // Set your desired logo size
      height: "50px", // Set your desired logo size
      borderRadius: "50%",
      backgroundColor: generateRandomLightColor(),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: "1.5em",
      marginRight: "10px",
    };

    return <div style={circleStyle}>{firstLetter}</div>;
  };

  // const [isSelectOpen, setIsSelectOpen] = useState(false);

  // INTEGRATION

  const qboClientId = "ABsjPuga53D6TISSkPmy9rAJG3l1Ix75QgcaR2QXTwvrZe8IqC";
  // const redirectUri = encodeURIComponent("http://localhost:3000/callback");
  const redirectUri = encodeURIComponent(
    "https://clearpathtocash.com/callback"
  );
  const scope = "com.intuit.quickbooks.accounting";
  const quickBooksRandomState = "fnEZiY2jxqAEMeE9NwNoc2wXK28Hzi74";

  const qboLogoutUrl = "https://app.qbo.intuit.com/app/logout";

  const handleConnect = () => {
    handleClose();
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = qboLogoutUrl;
    document.body.appendChild(iframe);
    setTimeout(() => {
      const authUri =
        `https://appcenter.intuit.com/connect/oauth2?` +
        `client_id=${qboClientId}` +
        `&response_type=code` +
        `&scope=${scope}` +
        `&redirect_uri=${redirectUri}` +
        `&state=${quickBooksRandomState}`;

      window.open(authUri, "_blank", "noopener,noreferrer");
    }, 1000);
  };

  const Expenses = [
    { name: "Taxes Paid", type: "select" },
    { name: "Interest Expense", type: "select" },
    { name: "Depreciation Expense", type: "select" },
    { name: "Amortization Expense", type: "select" },
    { name: "Average Payroll Expense", type: "select" },
    { name: "Days In Payroll", type: "input" },
    { name: "Family Excess", type: "input" },
    { name: "Capx", type: "input" },
    { name: "Debt Payments", type: "input" },
  ];

  const expenseKeyMap = {
    "Taxes Paid": "taxesPaid",
    "Interest Expense": "interestExpense",
    "Depreciation Expense": "depExpense",
    "Amortization Expense": "amortExpense",
    "Average Payroll Expense": "avgPayrollExpense",
    "Days In Payroll": "daysInPayroll",
    "Family Excess": "familyExcess",
    Capx: "capx",
    "Debt Payments": "debtPayments",
  };
  const handleMapFunction = (dateQbo) => {
    const sumValues = (keys) => {
      if (!keys || keys.length === 0) return "";
      const sum = keys.reduce((total, key) => {
        const value = Math.abs(Number(dateQbo.trialBalance[key]) || 0);
        return total + value;
      }, 0);
      return sum;
    };
    const data = {
      ownerGoal: {
        salesToAssets: "",
        grossProfitePercentage: "",
        netProfitePercentage: "",
        inventoryTurnoverRate: "",
        accountsReceivableTurnoverRate: "",
        accountsPayableTurnoverRate: "",
        ebidta: "",
        roa: "",
        roi: "",
        currentRatio: "",
        quickRatio: "",
        debtToEquityRatio: "",
        dcoh: "",
      },
      incomeStatement: {
        salesinUSD: dateQbo.profitAndLoss["Total Income"] || "",
        grossProfiteinUSD: dateQbo.profitAndLoss["Gross Profit"] || "",
        netProfiteBeforeTaxinUSD: dateQbo.profitAndLoss["Net Income"] || "",
        operatingExpense: dateQbo.profitAndLoss["Total Expenses"] || "",
        // taxesPaid: dateQbo.trialBalance[mapping.taxesPaid] || "",
        // interestExpense: dateQbo.trialBalance[mapping.interestExpense] || "",
        // depExpense: dateQbo.trialBalance[mapping.depExpense] || "",
        // amortExpense: dateQbo.trialBalance[mapping.amortExpense] || "",
        taxesPaid: sumValues(mapping.taxesPaid),
        interestExpense: sumValues(mapping.interestExpense),
        depExpense: sumValues(mapping.depExpense),
        amortExpense: sumValues(mapping.amortExpense),
        avgPayrollExpense: sumValues(mapping.avgPayrollExpense),
        daysInPayroll: mapping.daysInPayroll || "",
        familyExcess: mapping.familyExcess || "",

        costOfGoodsSoldinUSD: dateQbo.profitAndLoss["Cost of Goods Sold"] || "",
        // avgPayrollExpense:
        //   dateQbo.trialBalance[mapping.avgPayrollExpense] || "",
        // daysInPayroll: dateQbo.trialBalance[mapping.daysInPayroll] || "",
        // familyExcess: dateQbo.trialBalance[mapping.familyExcess] || "",
      },
      cashFlow: {
        operatingActivities: dateQbo.cashFlow["OPERATING ACTIVITIES"],
        investingActivities: dateQbo.cashFlow["INVESTING ACTIVITIES"],
        financingActivities: dateQbo.cashFlow["FINANCING ACTIVITIES"],
      },
      balanceSheet: {
        inventoryinUSD: dateQbo.balanceSheet["Inventory Asset"] || "",
        totalAssetsinUSD: dateQbo.balanceSheet["TOTAL ASSETS"] || "",
        grossFixedAssets: dateQbo.balanceSheet["Total Fixed Assets"] || "",
        accountReceivableinUSD:
          dateQbo.balanceSheet["Accounts Receivable (A/R)"] || "",
        accountPayableinUSD:
          dateQbo.balanceSheet["Accounts Payable (A/P)"] || "",
        retainedEarnings: dateQbo.balanceSheet["Retained Earnings"] || "",
        totalEquity: dateQbo.balanceSheet["Total Equity"] || "",
        longTermDebt: dateQbo.balanceSheet["Total Long-Term Liabilities"] || "",
        debtPayments: mapping.debtPayments || "",
        totalLiabilities: dateQbo.balanceSheet["Total Liabilities"] || "",
        currenAssets: dateQbo.balanceSheet["Total Current Assets"] || "",
        cash: (
          Number(dateQbo.balanceSheet?.Checking || 0) +
          Number(dateQbo.balanceSheet?.Savings || 0) +
          Number(dateQbo.balanceSheet["Cash on Hand"] || 0)
        ).toFixed(2),
        currenLiabilities:
          dateQbo.balanceSheet["Total Current Liabilities"] || "",
        capx: mapping.capx || "",
      },
      companyId: company._id,
      year: dateQbo.lastDay,
      NAICScode: company.NAICScode,
      periodName: dateQbo.periodName,
      dateType: dateQbo.period,
    };
    return data;
  };
  const extractFields = (response) => {
    const fields = {};
    const processRow = (row) => {
      if (row?.ColData && row?.ColData?.length > 1) {
        const key = row?.ColData[0]?.value;
        const value = row?.ColData[1]?.value;
        if (key && value !== undefined) {
          fields[key] = value;
        }
      }
      if (row?.Summary?.ColData && row?.Summary?.ColData?.length > 1) {
        const key = row?.Summary?.ColData[0]?.value;
        const value = row?.Summary?.ColData[1]?.value;
        if (key && value !== undefined) {
          fields[key] = value;
        }
      }
      if (row?.Rows?.Row) {
        row.Rows.Row.forEach(processRow);
      }
    };

    if (response?.Rows?.Row) {
      response.Rows.Row.forEach(processRow);
    }

    return fields;
  };
  const extractCashFlowSummaries = (response) => {
    const summaries = {};

    const processRow = (row) => {
      if (
        row?.Summary?.ColData &&
        row?.Summary?.ColData.length > 1 &&
        row?.Header?.ColData &&
        row?.Header?.ColData.length
      ) {
        const key = row?.Header?.ColData[0]?.value;
        const value = row?.Summary?.ColData[1]?.value;
        if (key && value !== undefined) {
          summaries[key] = value;
        }
      }
      if (row.Rows?.Row) {
        row.Rows.Row.forEach(processRow);
      }
    };

    if (response?.Rows?.Row) {
      response.Rows.Row.forEach(processRow);
    }

    return summaries;
  };
  const extractAccountSummaries = (queryResponse) => {
    const summaries = {};

    if (queryResponse?.QueryResponse?.Account?.length) {
      queryResponse?.QueryResponse?.Account.forEach((account) => {
        const accountType = account?.Name;
        const currentBalance = account?.CurrentBalance;

        if (accountType && currentBalance !== undefined) {
          summaries[accountType] = currentBalance;
        }
      });
    }

    return summaries;
  };
  const generateDateRanges = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const startYear = currentYear - 3;
    const ranges = { months: [], quarters: [], years: [], rollingYears: [] };

    //year
    for (let year = startYear; year <= currentYear; year++) {
      ranges.years.push({
        label: `Year ${year}`,
        startDate: `${year}-01-01`,
        endDate: `${year}-12-31`,
      });

      //quarter
      for (let quarter = 1; quarter <= 4; quarter++) {
        const startMonth = (quarter - 1) * 3 + 1;
        const endMonth = startMonth + 2;

        if (year === currentYear && startMonth > currentMonth) break;

        ranges.quarters.push({
          label: `Q${quarter} ${year}`,
          startDate: `${year}-${String(startMonth).padStart(2, "0")}-01`,
          endDate: `${year}-${String(endMonth).padStart(2, "0")}-${new Date(
            year,
            endMonth,
            0
          ).getDate()}`,
        });
      }

      //month
      for (
        let month = 1;
        month <= (year === currentYear ? currentMonth : 12);
        month++
      ) {
        ranges.months.push({
          label: `Month ${month} ${year}`,
          startDate: `${year}-${String(month).padStart(2, "0")}-01`,
          endDate: `${year}-${String(month).padStart(2, "0")}-${new Date(
            year,
            month,
            0
          ).getDate()}`,
        });
      }
    }
    //rolling year
    if (includeRollingYear) {
      const lastValidDate = new Date(currentYear, currentMonth, 0);
      for (let year = startYear; year <= currentYear; year++) {
        for (let month = 1; month <= 12; month++) {
          const rollingStartDate = new Date(year, month - 1, 1);
          if (rollingStartDate > lastValidDate) break;

          const rollingEndDate = new Date(
            rollingStartDate.getFullYear(),
            rollingStartDate.getMonth() + 12,
            0
          );
          if (rollingEndDate > lastValidDate) break;
          ranges.rollingYears.push({
            label: `${rollingStartDate.toLocaleString("default", {
              month: "short",
            })} ${rollingStartDate.getFullYear()} - ${rollingEndDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${rollingEndDate.getFullYear()}`,
            startDate: `${rollingStartDate.getFullYear()}-${String(
              rollingStartDate.getMonth() + 1
            ).padStart(2, "0")}-01`,
            endDate: `${rollingEndDate.getFullYear()}-${String(
              rollingEndDate.getMonth() + 1
            ).padStart(2, "0")}-${rollingEndDate.getDate()}`,
          });
        }
      }
    }
    return ranges;
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formatLastDay = (data) => {
    const date = new Date(data);
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const day = date.getDate() - 3;
    return `${month} ${day}, ${year}`;
  };

  const handleQboSubmit = async (accessToken) => {
    if (!accessToken) {
      console.error("Not connected");
      return;
    }
    handleClose();
    const toastId = toast.loading("Syncing in progress...");
    try {
      const ranges = generateDateRanges();
      const allRanges = [
        ...ranges.months,
        ...ranges.quarters,
        ...ranges.years,
        ...ranges.rollingYears,
      ];

      const endpoints = [
        { name: "balanceSheet", url: `${BASE_URL}/balanceSheet` },
        { name: "cashFlow", url: `${BASE_URL}/cashFlow` },
        { name: "profitAndLoss", url: `${BASE_URL}/profitAndLoss` },
        { name: "trialBalance", url: `${BASE_URL}/trialBalance` },
      ];
      for (const range of allRanges) {
        const requestData = {
          accessToken: accessToken,
          startDate: range.startDate,
          endDate: range.endDate,
        };
        const responses = await Promise.all(
          endpoints.map((endpoint) =>
            axios.post(endpoint.url, requestData).then((res) => ({
              name: endpoint.name,
              data: res.data.data,
            }))
          )
        );

        const transformedData = responses.reduce((acc, { name, data }) => {
          const fields =
            name === "cashFlow"
              ? extractCashFlowSummaries(data)
              : name === "trialBalance"
              ? extractAccountSummaries(data)
              : extractFields(data);
          return { ...acc, [name]: fields };
        }, {});

        let periodLabel = "";
        let period = "";
        let lastDay = "";

        if (range.label.startsWith("Month")) {
          period = "Month";
          const monthIndex = parseInt(range.label.split(" ")[1], 10) - 1;
          const year = parseInt(range.label.split(" ")[2], 10);
          periodLabel = `${monthNames[monthIndex]}_${year}`;

          const lastDate = new Date(year, monthIndex + 1, 0);
          lastDay = `${
            monthNames[monthIndex]
          } ${lastDate.getDate()}, ${lastDate.getFullYear()}`;
        } else if (range.label.startsWith("Q")) {
          period = "Quarter";
          periodLabel = range.label;

          const year = parseInt(range.label.split(" ")[1], 10);
          const quarter = parseInt(range.label[1], 10);

          const endMonth = quarter * 3;
          const lastDate = new Date(year, endMonth, 0);
          const dayBeforeLastDate = new Date(lastDate);
          dayBeforeLastDate.setDate(lastDate.getDate() - 1);
          lastDay = `${
            monthNames[dayBeforeLastDate.getMonth()]
          } ${dayBeforeLastDate.getDate()}, ${dayBeforeLastDate.getFullYear()}`;
        } else if (range.label.startsWith("Year")) {
          const year = parseInt(range.label.split(" ")[1], 10);
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth() + 1;

          if (year === currentYear) {
            period = "Year To Date";
            periodLabel = `Year_to_Date_${year}`;
            const lastDate = new Date(year, currentMonth - 1, 0);
            lastDay = `${
              monthNames[currentMonth - 1]
            } ${lastDate.getDate()}, ${lastDate.getFullYear()}`;
          } else {
            period = "Year";
            periodLabel = `Year_${year}`;

            const lastDate = new Date(year, 11, 31);
            const dayBeforeLastDate = new Date(lastDate);
            dayBeforeLastDate.setDate(lastDate.getDate() - 2);
            lastDay = `${
              monthNames[11]
            } ${dayBeforeLastDate.getDate()}, ${dayBeforeLastDate.getFullYear()}`;
          }
        } else {
          period = "Year";
          periodLabel = range.label;
          lastDay = formatLastDay(range.endDate);
        }

        const mapField = handleMapFunction({
          ...transformedData,
          periodName: periodLabel,
          lastDay,
          period,
        });

        const payload = {
          companyId: company._id,
        };
        await dispatch(addCompanyDetails(mapField, true));
        await dispatch(fetchCompanyDetails(payload));
      }
      toast.update(toastId, {
        render: "Syncing complete!",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
    } catch (error) {
      console.log(error);

      toast.update(toastId, {
        render: "Syncing Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const handleGetTrialBalanceData = async (accessToken) => {
    const data = {
      accessToken,
    };
    try {
      const response = await axios.post(`${BASE_URL}/trialBalance`, data);
      const extractedValue = extractAccountSummaries(response?.data?.data);
      setTrialBalanceData(extractedValue);
    } catch (err) {
      console.log(err);
    }
  };

  const hasFetched = useRef(false);
  const handleGetCredientials = async () => {
    if (hasFetched.current) return;
    try {
      hasFetched.current = true;
      const response = await axios.get(
        `${BASE_URL}/getSecrets?companyId=${company._id}`
      );
      setConnected(response?.data);
      return response?.data;
    } catch (err) {
      console.log(err);
    } finally {
      hasFetched.current = false;
    }
  };

  const handleExpenseField = (e) => {
    const { name, value } = e.target;
    const key = expenseKeyMap[name];

    if (key) {
      setMapping((prev) => ({
        ...prev,
        [key]: Array.isArray(prev[key]) ? value : value,
      }));
    }
  };
  const handleStoreReference = async () => {
    const data = {
      id: company._id,
      mapping,
    };
    const hasValues = Object.values(mapping).some(
      (value) =>
        (Array.isArray(value) && value.length > 0) ||
        (typeof value === "string" && value.trim() !== "")
    );

    if (hasValues) {
      try {
        const response = await axios.post(`${BASE_URL}/storeMapping`, data);
        if (response?.data?.data) {
          setMapping({
            avgPayrollExpense: response?.data?.data?.avgPayrollExpense || [],
            daysInPayroll: response?.data?.data?.daysInPayroll || "",
            depExpense: response?.data?.data?.depExpense || [],
            amortExpense: response?.data?.data?.amortExpense || [],
            interestExpense: response?.data?.data?.interestExpense || [],
            taxesPaid: response?.data?.data?.taxesPaid || [],
            capx: response?.data?.data?.capx || "",
            familyExcess: response?.data?.data?.familyExcess || "",
            debtPayments: response?.data?.data?.debtPayments || "",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getMappingReference = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/getMapping`, {
        params: { id: company._id },
      });
      return response?.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = () => {
    setMappingData(false);
    handleStoreReference();
    // setMapping(initialMapValue);
    if (refreshConnectRef.current) {
      handleRefreshData(connected);
    } else {
      handleQboSubmit(connected);
    }
  };
  const calculateStartAndEndDate = (periodName) => {
    const currentYear = new Date().getFullYear();
    if (periodName.startsWith("Year_to_Date")) {
      const year = parseInt(periodName.split("_").pop());
      const startDate = new Date(`${year}-01-01`);
      const endDate =
        year === currentYear ? new Date() : new Date(`${year}-12-31`);
      const period = "Year To Date";

      return { startDate, endDate, period };
    }

    // Format 1: year_{year} (e.g., "year_2025")
    if (/^Year_\d{4}$/.test(periodName)) {
      const year = periodName.split("_")[1];
      const startDate = new Date(`${year}-01-01`);
      const endDate = new Date(`${year}-12-31`);
      const period = "year";
      return { startDate, endDate, period };
    }
    // Format 2: {month}_{year} (e.g., "Feb_2024")
    if (/^[A-Za-z]+_\d{4}$/.test(periodName)) {
      const [month, year] = periodName.split("_");
      const startDate = new Date(`${month} 1, ${year}`);
      const endDate = new Date(startDate);
      const period = "month";
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);
      return { startDate, endDate, period };
    }

    // Format 3: q1_{year}, q2_{year}, q3_{year}, q4_{year} (e.g., "q1_2024")
    if (/^Q[1-4] \d{4}$/.test(periodName)) {
      const [quarter, year] = periodName.split(" ");
      let startDate, endDate;
      const period = "quarter";

      switch (quarter) {
        case "Q1":
          startDate = new Date(`${year}-01-01`);
          endDate = new Date(`${year}-03-31`);
          break;
        case "Q2":
          startDate = new Date(`${year}-04-01`);
          endDate = new Date(`${year}-06-30`);
          break;
        case "Q3":
          startDate = new Date(`${year}-07-01`);
          endDate = new Date(`${year}-09-30`);
          break;
        case "Q4":
          startDate = new Date(`${year}-10-01`);
          endDate = new Date(`${year}-12-31`);
          break;
        default:
          throw new Error("Invalid quarter format");
      }

      return { startDate, endDate, period };
    }

    // Format 4: {month} {year} - {month} {year} (e.g., "Feb 2024 - Jan 2025")
    if (/^[A-Za-z]+ \d{4} - [A-Za-z]+ \d{4}$/.test(periodName)) {
      const [startPeriod, endPeriod] = periodName.split(" - ");
      const [startMonth, startYear] = startPeriod.split(" ");
      const period = "year";
      const [endMonth, endYear] = endPeriod.split(" ");
      const startDate = new Date(`${startMonth} 1, ${startYear}`);
      const endDate = new Date(`${endMonth} 1, ${endYear}`);
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);
      return { startDate, endDate, period };
    }

    return null;
  };

  const handleRefreshData = async (accessToken) => {
    setRefreshConnect(false);
    setOpenRefresh(false);
    handleClose();
    const toastId = toast.loading("Refreshing Data...");
    try {
      const allRanges = selectedPeriodsRef.current
        .flatMap((selectedId) => {
          let name = "";
          if (selectedId?._id === "selectAll") {
            return periods
              .flatMap((period) => {
                const {
                  startDate,
                  endDate,
                  period: periodName,
                } = calculateStartAndEndDate(period.periodName) ?? {};
                if (startDate && endDate) {
                  return {
                    _id: period._id,
                    periodName: period.periodName,
                    date: period.date,
                    startDate: startDate.toISOString().split("T")[0],
                    endDate: endDate.toISOString().split("T")[0],
                    period: periodName,
                  };
                }
                return null;
              })
              .filter(Boolean);
          } else {
            name = selectedId.periodName;
          }
          if (name) {
            const { startDate, endDate, period } =
              calculateStartAndEndDate(selectedId.periodName) ?? {};

            if (startDate && endDate) {
              return {
                _id: selectedId._id,
                periodName: selectedId.periodName,
                date: selectedId.date,
                startDate: startDate.toISOString().split("T")[0],
                endDate: endDate.toISOString().split("T")[0],
                period: period,
              };
            }
          }
          return null;
        })
        .filter(Boolean);
      setSelectedPeriods([]);
      const endpoints = [
        { name: "balanceSheet", url: `${BASE_URL}/balanceSheet` },
        { name: "cashFlow", url: `${BASE_URL}/cashFlow` },
        { name: "profitAndLoss", url: `${BASE_URL}/profitAndLoss` },
        { name: "trialBalance", url: `${BASE_URL}/trialBalance` },
      ];

      for (const range of allRanges) {
        const requestData = {
          accessToken: accessToken,
          startDate: range.startDate,
          endDate: range.endDate,
        };

        const responses = await Promise.all(
          endpoints.map((endpoint) =>
            axios.post(endpoint.url, requestData).then((res) => ({
              name: endpoint.name,
              data: res.data.data,
            }))
          )
        );
        const transformedData = responses.reduce((acc, { name, data }) => {
          const fields =
            name === "cashFlow"
              ? extractCashFlowSummaries(data)
              : name === "trialBalance"
              ? extractAccountSummaries(data)
              : extractFields(data);
          return { ...acc, [name]: fields };
        }, {});
        const mapField = handleMapFunction({
          ...transformedData,
          periodName: range.periodName,
          lastDay: range.date,
          period: range.period,
        });
        const payload = {
          companyId: company._id,
        };
        await dispatch(addCompanyDetails(mapField, true));
        await dispatch(fetchCompanyDetails(payload));
      }
      toast.update(toastId, {
        render: "Data Refreshed Successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      // handleDisconnectBalanceData(connected)
      // setConnected("");
    } catch (error) {
      console.log(error);
      toast.update(toastId, {
        render: "Syncing Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
  const handleSyncData = async () => {
    const accesstoken = await handleGetCredientials();
    handleQboSubmit(accesstoken ?? connected);
  };
  const handleDisconnect = async () => {
    if (!connected) {
      alert("No active QuickBooks connection found.");
      return;
    }
    const data = {
      accessToken: connected,
      companyId: company._id,
    };
    const payload2 = {
      companyId: company._id,
    };
    try {
      const response = await axios.post(`${BASE_URL}/disconnect`, data);

      if (response) {
        setConnected("");
        console.log("Successfully disconnected from QuickBooks.");
        window.open("/disconnect", "_blank");
      } else {
        console.error("Failed to disconnect from QuickBooks.");
        toast.error("Error disconnecting from QuickBooks.");
      }
    } catch (error) {
      console.error("Error during QuickBooks disconnect:", error);
      toast.error("Error disconnecting from QuickBooks.");
    } finally {
      dispatch(fetchCompanyDetails(payload2));
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const accessToken = localStorage.getItem("access_token");
      localStorage.removeItem("access_token");
      if (accessToken) {
        setConnected(accessToken);
        setMappingData(true);
        handleGetTrialBalanceData(accessToken);
      }
    };
    handleStorageChange();
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  const handleRolling = (e) => {
    let { checked } = e.target;
    setIncludeRollingYear(checked);
  };
  const handleRefreshField = (e) => {
    setSelectedPeriods(e);
  };
  useEffect(() => {
    refreshConnectRef.current = refreshConnect;
  }, [refreshConnect]);
  useEffect(() => {
    if (connected) {
      const fetchMapping = async () => {
        try {
          const response = await getMappingReference();
          if (response?.data?.[0]) {
            setMapping({
              avgPayrollExpense: response?.data?.[0]?.avgPayrollExpense || [],
              daysInPayroll: response.data?.[0]?.daysInPayroll || "",
              depExpense: response.data?.[0]?.depExpense || [],
              amortExpense: response.data?.[0]?.amortExpense || [],
              interestExpense: response.data?.[0]?.interestExpense || [],
              taxesPaid: response.data?.[0]?.taxesPaid || [],
              capx: response.data?.[0]?.capx || "",
              familyExcess: response.data?.[0]?.familyExcess || "",
              debtPayments: response?.data?.[0]?.debtPayments || "",
            });

            // if (refreshConnect) {
            //   handleRefreshData(connected);
            // } else {
            //   handleQboSubmit(connected);
            // }
            // handleGetTrialBalanceData(connected);
            // if (refreshConnectRef.current) {
            //   handleOpen(true);
            // }
          } else {
            // handleGetTrialBalanceData(connected);
            // if (refreshConnectRef.current) {
            //   handleOpen(true);
            // }
          }
        } catch (error) {
          console.error("Error fetching mapping data:", error);
        }
      };

      fetchMapping();
    }
  }, [connected]);

  useEffect(() => {
    if (company?.qboIntegration) {
      handleGetCredientials();
    } else {
      setConnected("");
    }
  }, [company?.qboIntegration]);
  const formatNumber = (value) => {
    if (!value) return "";
    const numericValue = value.replace(/,/g, ""); // Remove existing commas before formatting
    return new Intl.NumberFormat("en-US").format(numericValue);
  };
  useEffect(() => {
    selectedPeriodsRef.current = selectedPeriods;
  }, [selectedPeriods]);
  const handlRefresheSave = async () => {
    setOpenRefresh(false);
    if (!connected) {
      handleConnect();
    } else {
      const accessToken = await handleGetCredientials();
      handleRefreshData(accessToken ?? connected);
    }
    setRefreshConnect(true);
  };
  return (
    <React.Fragment>
      <Layout title="Financial Data">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CompanyLogo companyName={company.companyName} />
            <Typography className="financial_data_heading">
              {company.companyName}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "none",
                      backgroundColor: "#F8F8FF",
                      color: "gray",
                      paddingY: "10px",
                      paddingX: "20px",
                    },
                  },
                }}
                title={
                  <pre style={{ fontSize: "13px", fontWeight: "600" }}>
                    {company.NAICStitle}
                  </pre>
                }
                placement="bottom"
                // arrow
              >
                <Typography
                  className="financial_data_right_heading"
                  sx={{ cursor: "pointer" }}
                >
                  NAICS Code: {company.NAICScode}
                </Typography>
              </Tooltip>
            </Typography>
          </Grid>
        </CardWrapper>
        <Box style={{ height: 10, width: "100%" }} />
        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            // marginBottom: 37,
          }}
        >
          <Typography>
            <Typography className="home_my_companies">
              Financial Data{" "}
              <InfoOutlinedIcon
                onClick={() => handlInfo("financialData")}
                className="infor_icon"
              />
            </Typography>
            <Box style={{ height: 10, width: "100%" }} />
            <span style={{ lineHeight: "1.3", color: "#7c7878", fontSize: 16 }}>
              <ul style={{ padding: "0px 0 0 16px" }}>
                <li>
                  <b>
                    Create or select a period to enter data to from the options
                    on the right.
                  </b>
                </li>
                <li>
                  <b>
                    Select a calculation set below and enter the required data
                    fields.
                  </b>
                </li>
                <li>
                  <b>Press SUBMIT to save the data to the period.</b>
                </li>
              </ul>
            </span>
          </Typography>
          <div className="qbo-Disconnect">
            <Button
              onClick={() => {
                if (!clientCheck(user, dispatch)) {
                  return;
                }
                handleOpen();
                setIsEdit(false);
                localStorage.removeItem("access_token");
                // setConnected("");

                // setValue("");
              }}
              className="home_add_company"
            >
              Select Input Method
            </Button>
            {connected && (
              <small>
                You are connected to QuickBooks.
                <button
                  onClick={handleDisconnect}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    background: "none",
                    border: "none",
                  }}
                >
                  Disconnect
                </button>
              </small>
            )}
          </div>
        </Grid>
        <Box style={{ height: 10, width: "100%" }} />
        <CardWrapper>
          <Grid container xs={12}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="All" {...a11yProps(0)} />
                  <Tab label="Hidden Cash" {...a11yProps(1)} />
                  <Tab label="Fast Money" {...a11yProps(2)} />
                  <Tab label="Home Run" {...a11yProps(3)} />
                  <Tab label="Simple Valuation" {...a11yProps(4)} />
                  <Tab label="Financial Doctor" {...a11yProps(5)} />
                </Tabs>
                <div style={{ display: "flex", gap: "15px" }}>
                  <Button
                    style={{ marginTop: "5px" }}
                    onClick={() => {
                      if (!clientCheck(user, dispatch)) {
                        return;
                      }
                      handleRefreshOpen();
                      localStorage.removeItem("access_token");
                      // setConnected("");
                      // setValue("");
                    }}
                    // className="home_add_company"
                  >
                    <Tooltip
                      title={
                        <span style={{ fontSize: "16px" }}>
                          Refresh QBO Data
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <CloudSyncIcon
                        sx={{ fontSize: "40px", color: "#00bdff" }}
                      />
                    </Tooltip>
                  </Button>
                  <Autocomplete
                    className="financial_data_autoselect"
                    sx={{
                      width: 230,
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#050505",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none !important",
                        },
                        "&:hover fieldset": {
                          border: "none !important",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none !important",
                        },
                        "& input:focus-visible": {
                          outline: "none !important",
                        },
                        "&.Mui-focused": {
                          boxShadow: "none !important",
                        },
                      },
                    }}
                    options={periods}
                    getOptionLabel={(option) => option.periodName}
                    value={
                      periods.find(
                        (period) => period.date === selectedPeriod
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let events = {
                          target: {
                            value: newValue.date,
                          },
                        };
                        handleDateChange(events);
                      } else {
                        handleDateChange({ target: { value: null } });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="custom-picker"
                        placeholder="Select Period"
                        variant="outlined"
                        style={{ border: "none" }}
                        sx={{
                          border: "none",
                          // border: "1px solid",
                          borderRadius: "22px",
                          "& .MuiOutlinedInput-root": {
                            "& input:focus-visible": {
                              outline: "none !important",
                            },
                          },
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "20px",
                          padding: "8px 2px",
                        }}
                      >
                        <span>{option.periodName}</span>
                        <Box sx={{ display: "flex", gap: 3 }}>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditDate(option.date);
                              handleDateChange({
                                target: { value: option?.date },
                              });
                            }}
                            size="small"
                            color="primary"
                            variant="text"
                          >
                            <img src={Edit} alt="Edit" height={20} />
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeletPeriodModelOpen(true);
                              handleDateChange({
                                target: { value: option?.date },
                              });
                            }}
                            size="small"
                            color="error"
                            variant="text"
                          >
                            <img src={Delete} alt="Delete" height={20} />
                          </span>
                        </Box>
                      </Box>
                    )}
                  />
                </div>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                {/* ALL */}
                <CustomTabPanel value={value} index={0}>
                  <Typography className="financial_data_sec_heading">
                    Owner`s Goal
                  </Typography>
                  <Grid container xs={12}>
                    {OWNER_GOAL.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography className="tabs_content_heading">
                            {item?.label}
                          </Typography>
                          {item.industryAvg ? (
                            <Typography className="subtitle_financial_data">
                              Industry Average:{" "}
                              {industryAverage &&
                                handleIndustryAvg(industryAverage, item)}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Box className="tabs_content_input_box">
                          {/* <input
                            type="number"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name]
                                ? formik.values[item.name]
                                : ""
                            }
                                                           onChange={(e) => {
    formik.handleChange(e); 
    setManualFields((prev) => ({ ...prev, [e.target.name]: true }));
  }}
                            style={{ backgroundColor: "red" }}
                            disabled={selectedPeriod ? false : true}
                          /> */}

                          <NumberInput
                            type="text"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name]
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            style={{ backgroundColor: "red" }}
                            disabled={selectedPeriod ? false : true}
                          />

                          {item.percentage ? (
                            <Box className="tabs_content_percent">%</Box>
                          ) : (
                            ""
                          )}
                          {item.turnover ? (
                            <Box className="tabs_content_percent">
                              {parseInt(formik.values[item.name])
                                ? `${(365 / formik.values[item.name]).toFixed(
                                    2
                                  )}x`
                                : "0.00x"}
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}

                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={`${handleClassForInputs()} ${
                                    selectedPeriod &&
                                    [
                                      "avgPayrollExpense",
                                      "daysInPayroll",
                                    ].includes(item.name) &&
                                    (formik.values[item.name] === null ||
                                      formik.values[item.name] === undefined ||
                                      formik.values[item.name] === "" ||
                                      Number.isNaN(
                                        parseFloat(
                                          formik.values[item.name]
                                            .toString()
                                            .replace(/,/g, "")
                                        )
                                      ))
                                      ? item.name === "avgPayrollExpense"
                                        ? "highlighted-red"
                                        : "highlighted-red-border"
                                      : manualFields[item.name]
                                      ? "lightblue-highlight"
                                      : ""
                                  }`}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={"1234123"}
                                  // value={formik.values[item.name]} // removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    setManualFields((prev) => ({
                                      ...prev,
                                      [e.target.name]: true,
                                    }));
                                  }}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}

                              <CurrencyInput
                                // className="tabs_content_input"
                                className={`${handleClassForInputs()} ${
                                  selectedPeriod &&
                                  (formik.values[item.name] === null ||
                                    formik.values[item.name] === undefined ||
                                    formik.values[item.name] === "" ||
                                    Number.isNaN(
                                      parseFloat(
                                        formik.values[item.name]
                                          .toString()
                                          .replace(/,/g, "")
                                      )
                                    ))
                                    ? "highlighted-red"
                                    : manualFields[item.name]
                                    ? "lightblue-highlight"
                                    : ""
                                }`}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]} removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  setManualFields((prev) => ({
                                    ...prev,
                                    [e.target.name]: true,
                                  }));
                                }}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>

                          <CurrencyInput
                            // className="tabs_content_input"
                            className={`${handleClassForInputs()} ${
                              selectedPeriod &&
                              (formik.values[item.name] === null ||
                                formik.values[item.name] === undefined ||
                                formik.values[item.name] === "" ||
                                Number.isNaN(
                                  parseFloat(
                                    formik.values[item.name]
                                      .toString()
                                      .replace(/,/g, "")
                                  )
                                ))
                                ? "highlighted-red"
                                : manualFields[item.name]
                                ? "lightblue-highlight"
                                : ""
                            }`}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]} // removed becuase null values were not updating

                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Cash Flow Statement
                  </Typography>
                  <Grid container xs={12}>
                    {CASH_FLOW_STATEMENT.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>

                          <CurrencyInput
                            // className="tabs_content_input"
                            className={`${handleClassForInputs()} ${
                              selectedPeriod &&
                              (formik.values[item.name] === null ||
                                formik.values[item.name] === undefined ||
                                formik.values[item.name] === "" ||
                                Number.isNaN(
                                  parseFloat(
                                    formik.values[item.name]
                                      .toString()
                                      .replace(/,/g, "")
                                  )
                                ))
                                ? "highlighted-red"
                                : manualFields[item.name]
                                ? "lightblue-highlight"
                                : ""
                            }`}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                {/* HIDDEN CASH */}
                <CustomTabPanel value={value} index={1}>
                  <Typography className="financial_data_sec_heading">
                    Owner`s Goal
                  </Typography>
                  <Grid container xs={12}>
                    {HIDDEN_CASH_OWNER_GOAL.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography className="tabs_content_heading">
                            {item?.label}
                          </Typography>
                          {item.industryAvg ? (
                            <Typography className="subtitle_financial_data">
                              Industry Average:{" "}
                              {industryAverage &&
                                handleIndustryAvg(industryAverage, item)}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Box className="tabs_content_input_box">
                          {/* <input
                            type="number"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            // value={formik.values[item.name]} removed becuase null values were not updating
                            value={
                              formik.values[item.name]
                                ? formik.values[item.name]
                                : ""
                            }
                                      onChange={(e) => {
    formik.handleChange(e); 
    setManualFields((prev) => ({ ...prev, [e.target.name]: true }));
  }}
                            disabled={selectedPeriod ? false : true}
                          /> */}

                          <NumberInput
                            type="text"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            // value={formik.values[item.name]} removed becuase null values were not updating
                            value={
                              formik.values[item.name]
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            disabled={selectedPeriod ? false : true}
                          />

                          {item.turnover ? (
                            <Box className="tabs_content_percent">
                              {parseInt(formik.values[item.name])
                                ? `${(365 / formik.values[item.name]).toFixed(
                                    2
                                  )}x`
                                : "0.00x"}
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {HIDDEN_CASH_INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}
                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={handleClassForInputs()}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={formik.values[item.name]} removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    setManualFields((prev) => ({
                                      ...prev,
                                      [e.target.name]: true,
                                    }));
                                  }}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}
                              <CurrencyInput
                                // className="tabs_content_input"
                                className={`${handleClassForInputs()} ${
                                  selectedPeriod &&
                                  (formik.values[item.name] === null ||
                                    formik.values[item.name] === undefined ||
                                    formik.values[item.name] === "" ||
                                    Number.isNaN(
                                      parseFloat(
                                        formik.values[item.name]
                                          .toString()
                                          .replace(/,/g, "")
                                      )
                                    ))
                                    ? "highlighted-red"
                                    : manualFields[item.name]
                                    ? "lightblue-highlight"
                                    : ""
                                }`}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]} // removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  setManualFields((prev) => ({
                                    ...prev,
                                    [e.target.name]: true,
                                  }));
                                }}
                                disabled={selectedPeriod ? false : true}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {HIDDEN_CASH_BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]}// removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                {/* FAST MONEY */}
                <CustomTabPanel value={value} index={2}>
                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {FAST_MONEY_INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}
                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={`${handleClassForInputs()} ${
                                    selectedPeriod &&
                                    [
                                      "avgPayrollExpense",
                                      "daysInPayroll",
                                    ].includes(item.name) &&
                                    (formik.values[item.name] === null ||
                                      formik.values[item.name] === undefined ||
                                      formik.values[item.name] === "" ||
                                      Number.isNaN(
                                        parseFloat(
                                          formik.values[item.name]
                                            .toString()
                                            .replace(/,/g, "")
                                        )
                                      ))
                                      ? item.name === "avgPayrollExpense"
                                        ? "highlighted-red"
                                        : manualFields[item.name]
                                        ? "lightblue-highlight"
                                        : "highlighted-red-border"
                                      : ""
                                  }`}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={formik.values[item.name]} // removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    setManualFields((prev) => ({
                                      ...prev,
                                      [e.target.name]: true,
                                    }));
                                  }}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}
                              <CurrencyInput
                                // className="tabs_content_input"
                                className={`${handleClassForInputs()} ${
                                  selectedPeriod &&
                                  (formik.values[item.name] === null ||
                                    formik.values[item.name] === undefined ||
                                    formik.values[item.name] === "" ||
                                    Number.isNaN(
                                      parseFloat(
                                        formik.values[item.name]
                                          .toString()
                                          .replace(/,/g, "")
                                      )
                                    ))
                                    ? "highlighted-red"
                                    : manualFields[item.name]
                                    ? "lightblue-highlight"
                                    : ""
                                }`}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]} // removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  setManualFields((prev) => ({
                                    ...prev,
                                    [e.target.name]: true,
                                  }));
                                }}
                                disabled={selectedPeriod ? false : true}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {FAST_MONEY_BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={`${handleClassForInputs()} ${
                              selectedPeriod &&
                              (formik.values[item.name] === null ||
                                formik.values[item.name] === undefined ||
                                formik.values[item.name] === "" ||
                                Number.isNaN(
                                  parseFloat(
                                    formik.values[item.name]
                                      .toString()
                                      .replace(/,/g, "")
                                  )
                                ))
                                ? "highlighted-red"
                                : manualFields[item.name]
                                ? "lightblue-highlight"
                                : ""
                            }`}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]}// removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                {/* HOME RUN */}
                <CustomTabPanel value={value} index={3}>
                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {HOME_RUN_INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}
                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={`${handleClassForInputs()} ${
                                    selectedPeriod &&
                                    (formik.values[item.name] === null ||
                                      formik.values[item.name] === undefined ||
                                      formik.values[item.name] === "" ||
                                      Number.isNaN(
                                        parseFloat(
                                          formik.values[item.name]
                                            .toString()
                                            .replace(/,/g, "")
                                        )
                                      ))
                                      ? "highlighted-red"
                                      : manualFields[item.name]
                                      ? "lightblue-highlight"
                                      : ""
                                  }`}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={formik.values[item.name]}// removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    setManualFields((prev) => ({
                                      ...prev,
                                      [e.target.name]: true,
                                    }));
                                  }}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}
                              <CurrencyInput
                                // className="tabs_content_input"
                                className={`${handleClassForInputs()} ${
                                  selectedPeriod &&
                                  (formik.values[item.name] === null ||
                                    formik.values[item.name] === undefined ||
                                    formik.values[item.name] === "" ||
                                    Number.isNaN(
                                      parseFloat(
                                        formik.values[item.name]
                                          .toString()
                                          .replace(/,/g, "")
                                      )
                                    ))
                                    ? "highlighted-red"
                                    : manualFields[item.name]
                                    ? "lightblue-highlight"
                                    : ""
                                }`}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]}// removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  setManualFields((prev) => ({
                                    ...prev,
                                    [e.target.name]: true,
                                  }));
                                }}
                                disabled={selectedPeriod ? false : true}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {HOME_RUN_BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={`${handleClassForInputs()} ${
                              selectedPeriod &&
                              (formik.values[item.name] === null ||
                                formik.values[item.name] === undefined ||
                                formik.values[item.name] === "" ||
                                Number.isNaN(
                                  parseFloat(
                                    formik.values[item.name]
                                      .toString()
                                      .replace(/,/g, "")
                                  )
                                ))
                                ? "highlighted-red"
                                : manualFields[item.name]
                                ? "lightblue-highlight"
                                : ""
                            }`}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Cash Flow Statement
                  </Typography>
                  <Grid container xs={12}>
                    {HOME_RUN_CASH_FLOW_STATEMENT.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={`${handleClassForInputs()} ${
                              selectedPeriod &&
                              (formik.values[item.name] === null ||
                                formik.values[item.name] === undefined ||
                                formik.values[item.name] === "" ||
                                Number.isNaN(
                                  parseFloat(
                                    formik.values[item.name]
                                      .toString()
                                      .replace(/,/g, "")
                                  )
                                ))
                                ? "highlighted-red"
                                : manualFields[item.name]
                                ? "lightblue-highlight"
                                : ""
                            }`}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]}// removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                {/* SVS */}
                <CustomTabPanel value={value} index={4}>
                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {SVS_INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}
                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={`${handleClassForInputs()} ${
                                    selectedPeriod &&
                                    (formik.values[item.name] === null ||
                                      formik.values[item.name] === undefined ||
                                      formik.values[item.name] === "" ||
                                      Number.isNaN(
                                        parseFloat(
                                          formik.values[item.name]
                                            .toString()
                                            .replace(/,/g, "")
                                        )
                                      ))
                                      ? "highlighted-red"
                                      : manualFields[item.name]
                                      ? "lightblue-highlight"
                                      : ""
                                  }`}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={formik.values[item.name]} // removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    setManualFields((prev) => ({
                                      ...prev,
                                      [e.target.name]: true,
                                    }));
                                  }}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}
                              <CurrencyInput
                                // className="tabs_content_input"
                                className={`${handleClassForInputs()} ${
                                  selectedPeriod &&
                                  (formik.values[item.name] === null ||
                                    formik.values[item.name] === undefined ||
                                    formik.values[item.name] === "" ||
                                    Number.isNaN(
                                      parseFloat(
                                        formik.values[item.name]
                                          .toString()
                                          .replace(/,/g, "")
                                      )
                                    ))
                                    ? "highlighted-red"
                                    : manualFields[item.name]
                                    ? "lightblue-highlight"
                                    : ""
                                }`}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]}// removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  setManualFields((prev) => ({
                                    ...prev,
                                    [e.target.name]: true,
                                  }));
                                }}
                                disabled={selectedPeriod ? false : true}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {SVS_BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={`${handleClassForInputs()} ${
                              selectedPeriod &&
                              (formik.values[item.name] === null ||
                                formik.values[item.name] === undefined ||
                                formik.values[item.name] === "" ||
                                Number.isNaN(
                                  parseFloat(
                                    formik.values[item.name]
                                      .toString()
                                      .replace(/,/g, "")
                                  )
                                ))
                                ? "highlighted-red"
                                : manualFields[item.name]
                                ? "lightblue-highlight"
                                : ""
                            }`}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]}// removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                {/* Financial Doctor */}
                <CustomTabPanel value={value} index={5}>
                  <CustomAccordion>
                    <CustomAccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="financial_data_sec_heading"
                    >
                      Owner`s Goal
                    </CustomAccordionSummary>
                    <AccordionDetails>
                      <Grid container xs={12}>
                        {FINANCIAL_DATA_OWNER_GOAL.map((item, index) => (
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              {item.industryAvg ? (
                                <Typography className="subtitle_financial_data">
                                  Industry Average:{" "}
                                  {industryAverage &&
                                    handleIndustryAvg(industryAverage, item)}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Box className="tabs_content_input_box">
                              {/* <input
                                type="number"
                                // className="tabs_content_input"
                                className={handleClassForInputs()}
                                placeholder={item?.placeholder}
                                name={item.name}
                                // value={formik.values[item.name]} // removed becuase null values were not updating
                                value={
                                  formik.values[item.name]
                                    ? formik.values[item.name]
                                    : ""
                                }
                                                               onChange={(e) => {
    formik.handleChange(e); 
    setManualFields((prev) => ({ ...prev, [e.target.name]: true }));
  }}
                                disabled={selectedPeriod ? false : true}
                              /> */}

                              <NumberInput
                                type="text"
                                // className="tabs_content_input"
                                className={`${handleClassForInputs()} ${
                                  selectedPeriod &&
                                  (formik.values[item.name] === null ||
                                    formik.values[item.name] === undefined ||
                                    formik.values[item.name] === "" ||
                                    Number.isNaN(
                                      parseFloat(
                                        formik.values[item.name]
                                          .toString()
                                          .replace(/,/g, "")
                                      )
                                    ))
                                    ? "highlighted-red"
                                    : manualFields[item.name]
                                    ? "lightblue-highlight"
                                    : ""
                                }`}
                                placeholder={item?.placeholder}
                                name={item.name}
                                // value={formik.values[item.name]} // removed becuase null values were not updating
                                value={
                                  formik.values[item.name]
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  setManualFields((prev) => ({
                                    ...prev,
                                    [e.target.name]: true,
                                  }));
                                }}
                                disabled={selectedPeriod ? false : true}
                              />

                              {item.percentage ? (
                                <Box className="tabs_content_percent">%</Box>
                              ) : (
                                ""
                              )}
                              {item.turnover ? (
                                <Box className="tabs_content_percent">
                                  {parseInt(formik.values[item.name])
                                    ? `${(
                                        365 / formik.values[item.name]
                                      ).toFixed(2)}x`
                                    : "0.00x"}
                                </Box>
                              ) : (
                                ""
                              )}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </CustomAccordion>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />

                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {FINANCIAL_DATA_INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}
                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={`${handleClassForInputs()} ${
                                    selectedPeriod &&
                                    [
                                      "avgPayrollExpense",
                                      "daysInPayroll",
                                    ].includes(item.name) &&
                                    (formik.values[item.name] === null ||
                                      formik.values[item.name] === undefined ||
                                      formik.values[item.name] === "" ||
                                      Number.isNaN(
                                        parseFloat(
                                          formik.values[item.name]
                                            .toString()
                                            .replace(/,/g, "")
                                        )
                                      ))
                                      ? item.name === "avgPayrollExpense"
                                        ? "highlighted-red"
                                        : manualFields[item.name]
                                        ? "lightblue-highlight"
                                        : "highlighted-red-border"
                                      : ""
                                  }`}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={formik.values[item.name]}// removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    setManualFields((prev) => ({
                                      ...prev,
                                      [e.target.name]: true,
                                    }));
                                  }}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}
                              <CurrencyInput
                                // className="tabs_content_input"
                                className={`${handleClassForInputs()} ${
                                  selectedPeriod &&
                                  (formik.values[item.name] === null ||
                                    formik.values[item.name] === undefined ||
                                    formik.values[item.name] === "" ||
                                    Number.isNaN(
                                      parseFloat(
                                        formik.values[item.name]
                                          .toString()
                                          .replace(/,/g, "")
                                      )
                                    ))
                                    ? "highlighted-red"
                                    : manualFields[item.name]
                                    ? "lightblue-highlight"
                                    : ""
                                }`}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]}// removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  setManualFields((prev) => ({
                                    ...prev,
                                    [e.target.name]: true,
                                  }));
                                }}
                                disabled={selectedPeriod ? false : true}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {FINANCIAL_DATA_BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={`${handleClassForInputs()} ${
                              selectedPeriod &&
                              (formik.values[item.name] === null ||
                                formik.values[item.name] === undefined ||
                                formik.values[item.name] === "" ||
                                Number.isNaN(
                                  parseFloat(
                                    formik.values[item.name]
                                      .toString()
                                      .replace(/,/g, "")
                                  )
                                ))
                                ? "highlighted-red"
                                : manualFields[item.name]
                                ? "lightblue-highlight"
                                : ""
                            }`}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Cash Flow Statement
                  </Typography>
                  <Grid container xs={12}>
                    {FINANCIAL_DATA_CASH_FLOW_STATEMENT.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={`${handleClassForInputs()} ${
                              selectedPeriod &&
                              (formik.values[item.name] === null ||
                                formik.values[item.name] === undefined ||
                                formik.values[item.name] === "" ||
                                Number.isNaN(
                                  parseFloat(
                                    formik.values[item.name]
                                      .toString()
                                      .replace(/,/g, "")
                                  )
                                ))
                                ? "highlighted-red"
                                : manualFields[item.name]
                                ? "lightblue-highlight"
                                : ""
                            }`}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              setManualFields((prev) => ({
                                ...prev,
                                [e.target.name]: true,
                              }));
                            }}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                <Divider />
                <Grid
                  container
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    type="submit"
                    className="home_add_company"
                    style={{ marginTop: 30 }}
                    disabled={companyDetailsLoading}
                  >
                    Submit
                    {companyDetailsLoading ? (
                      <CircularProgress
                        color="inherit"
                        style={{
                          height: "20px",
                          width: "20px",
                          marginLeft: "5%",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingRight: "30px",
                    paddingTop: "5px",
                  }}
                >
                  <small className="red" style={{ fontWeight: "600" }}>
                    {(formik.touched.salesToAssets &&
                      formik.errors.salesToAssets) ||
                    (formik.touched.grossProfitePercentage &&
                      formik.errors.grossProfitePercentage) ||
                    (formik.touched.netProfitePercentage &&
                      formik.errors.netProfitePercentage) ||
                    (formik.touched.inventoryTurnoverRate &&
                      formik.errors.inventoryTurnoverRate) ||
                    (formik.touched.accountsReceivableTurnoverRate &&
                      formik.errors.accountsReceivableTurnoverRate) ||
                    (formik.touched.salesinUSD && formik.errors.salesinUSD) ||
                    (formik.touched.totalAssetsinUSD &&
                      formik.errors.totalAssetsinUSD) ||
                    (formik.touched.grossFixedAssets &&
                      formik.errors.grossFixedAssets) ||
                    (formik.touched.grossProfiteinUSD &&
                      formik.errors.grossProfiteinUSD) ||
                    (formik.touched.netProfiteBeforeTaxinUSD &&
                      formik.errors.netProfiteBeforeTaxinUSD) ||
                    (formik.touched.operatingActivities &&
                      formik.errors.operatingActivities) ||
                    (formik.touched.investingActivities &&
                      formik.errors.investingActivities) ||
                    (formik.touched.financingActivities &&
                      formik.errors.financingActivities) ||
                    (formik.touched.operatingExpense &&
                      formik.errors.operatingExpense) ||
                    (formik.touched.taxesPaid && formik.errors.taxesPaid) ||
                    (formik.touched.interestExpense &&
                      formik.errors.interestExpense) ||
                    (formik.touched.depExpense && formik.errors.depExpense) ||
                    (formik.touched.amortExpense &&
                      formik.errors.amortExpense) ||
                    (formik.touched.costOfGoodsSoldinUSD &&
                      formik.errors.costOfGoodsSoldinUSD) ||
                    (formik.touched.inventoryinUSD &&
                      formik.errors.inventoryinUSD) ||
                    (formik.touched.capx && formik.errors.capx) ||
                    (formik.touched.familyExcess &&
                      formik.errors.familyExcess) ||
                    (formik.touched.accountReceivableinUSD &&
                      formik.errors.accountReceivableinUSD) ||
                    (formik.touched.accountPayableinUSD &&
                      formik.errors.accountPayableinUSD) ||
                    (formik.touched.retainedEarnings &&
                      formik.errors.retainedEarnings) ||
                    (formik.touched.totalEquity && formik.errors.totalEquity) ||
                    (formik.touched.longTermDebt &&
                      formik.errors.longTermDebt) ||
                    (formik.touched.debtPayments &&
                      formik.errors.debtPayments) ||
                    (formik.touched.totalLiabilities &&
                      formik.errors.totalLiabilities) ||
                    (company?.isService &&
                      formik.touched.avgPayrollExpense &&
                      formik.errors.avgPayrollExpense) ||
                    (formik.touched.currenAssets &&
                      formik.errors.currenAssets) ||
                    (formik.touched.cash && formik.errors.cash) ||
                    (formik.touched.currenLiabilities &&
                      formik.errors.currenLiabilities) ||
                    (company?.isService &&
                      formik.touched.daysInPayroll &&
                      formik.errors.daysInPayroll)
                      ? "All fields are required"
                      : ""}
                  </small>
                </Box>
              </form>
            </Box>
          </Grid>
        </CardWrapper>
      </Layout>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!userInput && !qboInput && !isEdit && (
            <div>
              <h2 style={{ textAlign: "center" }}>Select Input Method</h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "row",
                  margin: "25px 0px",
                }}
              >
                <div
                  style={{
                    border: "2px solid black",
                    display: "flex",
                    flexDirection: "row",
                    gap: 13,
                    padding: "6px 20px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setUserInput(true);
                  }}
                >
                  <FaEdit size={32} />
                  <span style={{ fontSize: "larger" }}>User Input</span>
                </div>
                <div
                  style={{
                    border: "2px solid black",

                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setQboInput(true);
                  }}
                >
                  <img
                    className="icons_quickbooks_data"
                    src={quickbooks}
                    alt="Quickbooks"
                  />
                </div>
              </div>
            </div>
          )}
          {qboInput && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <FaArrowLeft
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setQboInput(false);
                  // setConnected("");
                }}
              />

              <img
                style={{ height: "45px", width: "200px" }}
                src={quickbooks1}
                alt="Quickbooks"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <FormControlLabel
                  onChange={handleRolling}
                  control={<Checkbox />}
                  label="Include Rolling Years"
                />
                {connected ? (
                  <Button
                    onClick={() => handleSyncData()}
                    type="submit"
                    className="syncdata_company"
                  >
                    Sync Data
                  </Button>
                ) : (
                  <Button onClick={handleConnect}>
                    <img
                      className="qboConnect"
                      src={qbConnector}
                      alt="Connect"
                    />
                  </Button>
                )}
              </div>
            </div>
          )}
          {(userInput || isEdit) && (
            <div>
              {!isEdit && (
                <>
                  <FaArrowLeft
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setUserInput(false);
                    }}
                  />
                  <h2 style={{ textAlign: "center" }}>Enter Details</h2>
                </>
              )}
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography className="main_typo3" sx={{ mt: 1 }}>
                    Period Name
                  </Typography>
                </div>
                <input
                  className="customize-input1"
                  placeholder="Enter Period Name"
                  value={periodName ? periodName : ""}
                  color="white"
                  name="periodName"
                  type="text"
                  onChange={(e) => {
                    setPeriodName(e.target.value);
                  }}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    className="main_typo3"
                    sx={{ mt: 1, width: "73%" }}
                  >
                    Last Day of Period
                  </Typography>
                  <Typography className="main_typo3" sx={{ mt: 1 }}>
                    Date Type
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  id="custom-picker"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(selectedDate)}
                      style={{ borderRadius: "22px" }}
                      // disabled={isEdit}
                      onChange={(newValue) => {
                        setSelectedDate(newValue.$d);
                      }}
                    />
                  </LocalizationProvider>
                  <FormControl className="currencies-form">
                    <Select
                      defaultValue={periodData.dateType}
                      label="Date Type"
                      onChange={(e) => {
                        setDateType(e.target.value);
                      }}
                      sx={{ border: "1px solid", borderRadius: "22px" }}
                    >
                      {PERIOD_DURATION.map((options, index) => {
                        return (
                          <MenuItem key={index} value={options.label}>
                            {options.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="login-btn"
                    disabled={companyDetailsLoading}
                    onClick={handleSubmitPeriod}
                    id="period-details-btn"
                  >
                    Submit
                    {companyDetailsLoading ? (
                      <CircularProgress
                        color="inherit"
                        style={{
                          height: "20px",
                          width: "20px",
                          marginLeft: "5%",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </Box>
              </div>
            </div>
          )}
        </Box>
      </Modal>
      <Modal
        open={openRefresh}
        onClose={handleRefreshClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{ display: "flex", gap: "15px", flexDirection: "column" }}
          >
            <Grid item sm={10}>
              <FormControl
                sx={{
                  borderRadius: "22px",
                  padding: "8px",
                  gap: "10px",
                  width: "320px",
                }}
              >
                <h2>Select Periods </h2>
                <Autocomplete
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none !important",
                      },
                      "&:hover fieldset": {
                        border: "none !important",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none !important",
                      },
                      "& input:focus-visible": {
                        outline: "none !important",
                      },
                      "&.Mui-focused": {
                        boxShadow: "none !important",
                      },
                    },
                  }}
                  multiple
                  value={selectedPeriods}
                  onChange={(e, newValue) => handleRefreshField(newValue)}
                  options={[
                    { periodName: "All", _id: "selectAll" },
                    ...periods,
                  ]}
                  getOptionLabel={(option) => option.periodName}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Periods"
                      getLimitTags={4}
                      variant="outlined"
                      sx={{
                        border: "1px solid",
                        borderRadius: "22px",
                        "& .MuiOutlinedInput-root": {
                          "& input:focus-visible": {
                            outline: "none !important",
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(selected, getTagProps) => {
                    const visibleTags = selected.slice(0, 4);
                    const remainingCount = selected.length - 4;
                    return (
                      <>
                        {visibleTags.map((option, index) => (
                          <Chip
                            key={option._id}
                            label={option.periodName}
                            {...getTagProps({ index })}
                          />
                        ))}
                        {remainingCount > 0 && (
                          <Chip label={`+${remainingCount}`} />
                        )}
                      </>
                    );
                  }}
                />
              </FormControl>
              {selectedPeriods.length > 0 && (
                <div style={{ margin: "10px" }}>
                  <span style={{ fontSize: "small", color: "#3c64b5" }}>
                    Selected Periods :
                  </span>
                  <span style={{ fontSize: "small", color: "#3b3a37" }}>
                    {selectedPeriods
                      .map((selectedId) => {
                        return selectedId.periodName;
                      })
                      .filter(Boolean)
                      .join(", ")}
                  </span>
                </div>
              )}
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handlRefresheSave}
              >
                <CloudSyncIcon />
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={mappingData}
        onClose={handleMappingClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {connected && (
            <div
              style={{ display: "flex", gap: "15px", flexDirection: "column" }}
            >
              <h3 style={{ color: "#1A3C65" }}>Please Fill Out the Details</h3>
              <Grid container spacing={2}>
                {Expenses.map((expense, index) => (
                  <Grid item sm={6} key={index}>
                    <FormControl
                      fullWidth
                      sx={{
                        borderRadius: "22px",
                        padding: "8px",
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "5px",
                      }}
                    >
                      <h5>{expense.name}</h5>
                      {expense.type === "select" ? (
                        <Autocomplete
                          multiple
                          options={Object.keys(trialBalanceData)}
                          value={mapping[expenseKeyMap[expense.name]] || []}
                          onChange={(event, newValue) =>
                            handleExpenseField({
                              target: { name: expense.name, value: newValue },
                            })
                          }
                          getLimitTags={1}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Search..."
                              sx={{
                                borderRadius: "22px",
                                width: "100%",
                                "& .MuiOutlinedInput-root": {
                                  "& input:focus-visible": {
                                    outline: "none !important",
                                  },
                                },
                              }}
                            />
                          )}
                          renderTags={(selected, getTagProps) => (
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              sx={{
                                flexWrap: "nowrap",
                                overflow: "hidden",
                                width: "100%",
                              }}
                            >
                              <Chip
                                key={selected[0]}
                                label={`${selected[0].slice(0, 3)}${
                                  selected[0].length > 3 ? "..." : ""
                                }`}
                                {...getTagProps({ index: 0 })}
                              />
                              {selected.length > 1 && (
                                <Chip label={`+${selected.length - 1}`} />
                              )}
                            </Stack>
                          )}
                          sx={{
                            border: "1px solid",
                            borderRadius: "22px",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                              },
                              "& input:focus-visible": {
                                outline: "none !important",
                              },
                            },
                          }}
                        />
                      ) : (
                        <TextField
                          placeholder={`Enter ${expense.name}`}
                          name={expense.name}
                          value={
                            mapping[expenseKeyMap[expense.name]]
                              ? formatNumber(
                                  mapping[expenseKeyMap[expense.name]]
                                )
                              : ""
                          }
                          onChange={(e) => handleExpenseField(e)}
                          variant="outlined"
                          InputProps={{
                            startAdornment:
                              expense.name === "Days In Payroll" ? null : (
                                <InputAdornment
                                  style={{ marginTop: "1px" }}
                                  position="start"
                                >
                                  {currency_symbols[company.currency] || "$"}
                                </InputAdornment>
                              ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none !important",
                              },
                              "&:hover fieldset": {
                                border: "none !important",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none !important",
                              },
                              "& input:focus-visible": {
                                outline: "none !important",
                              },
                            },
                            border: "1px solid",
                            borderRadius: "22px !important",

                            "& input": {
                              borderRadius: "22px !important",
                            },
                            "&:-webkit-autofill": {
                              boxShadow:
                                "0 0 0px 1000px white inset !important",
                              "-webkit-text-fill-color": "black !important",
                              borderRadius: "22px !important",
                            },
                          }}
                        />
                      )}
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
      <div>
        <ToastContainer />
      </div>
      <Modal
        open={deletPeriodModelOpen}
        onClose={handleDeletPeriodModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Typography variant="h6" align="center">
              Are you sure you want to delete this period?
            </Typography>

            <Button
              className="login-btn"
              sx={{ float: "left" }}
              onClick={handleDeleteDate}
              // disabled={cancelSubLoading}
            >
              Yes
              {/* {cancelSubLoading ? (
                <CircularProgress
                  color="inherit"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5%",
                  }}
                />
              ) : (
                ""
              )} */}
            </Button>
            <Button
              className="login-btn"
              // disabled={cancelSubLoading}
              sx={{ float: "right" }}
              onClick={handleDeletPeriodModelClose}
            >
              Cancel
            </Button>
          </Box>
        </>
      </Modal>
      <SnackBar
        setOpen={setOpenSnackBar}
        open={openSnackbar}
        snackType={snackType2}
        msg={message2}
      />
    </React.Fragment>
  );
};
export default FinancialData;
