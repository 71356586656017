import React, { useRef, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "../../assets/edit-icon.svg";
import SaveIcon from "../../assets/save-icon.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { addNote } from "../../redux/actions/notes";
import { useDispatch, useSelector } from "react-redux";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import Tooltip from "@mui/material/Tooltip";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CompressIcon from "@mui/icons-material/Compress";
import EditNoteIcon from "@mui/icons-material/EditNote";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo"; //
import { autoCorrect } from "../../openAI";

const CustomToolbar = ({ handleRevert, historyText }) => (
  <div id="toolbar">
    <select className="ql-header" defaultValue="" onChange={(e) => e.persist()}>
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="">Normal</option>
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-link" />
    <button className="ql-list" value="ordered">
      Ordered List
    </button>
    <button className="ql-list" value="bullet">
      Bullet List
    </button>
    <button className="ql-auto-correct">
      <Tooltip
        title={<span style={{ fontSize: "16px" }}>Auto-correct</span>}
        arrow
        placement="top"
      >
        <SpellcheckIcon />
      </Tooltip>
    </button>
    <button className="ql-rephrase">
      <Tooltip
        title={<span style={{ fontSize: "16px" }}>Rephrase</span>}
        arrow
        placement="top"
      >
        <AutoAwesomeIcon />
      </Tooltip>
    </button>
    <button className="ql-summarise">
      <Tooltip
        title={<span style={{ fontSize: "16px" }}>Summarize</span>}
        arrow
        placement="top"
      >
        <CompressIcon />
      </Tooltip>
    </button>
    <button className="ql-continue-writing">
      <Tooltip
        title={<span style={{ fontSize: "16px" }}>Continue Writing</span>}
        arrow
        placement="top"
      >
        <EditNoteIcon />
      </Tooltip>
    </button>
    <button className="ql-undo">
      <Tooltip
        title={<span style={{ fontSize: "16px" }}>Undo</span>}
        arrow
        placement="top"
      >
        <UndoIcon />
      </Tooltip>
    </button>
    <button className="ql-redo">
      <Tooltip
        title={<span style={{ fontSize: "16px" }}>Redo</span>}
        arrow
        placement="top"
      >
        <RedoIcon />
      </Tooltip>
    </button>
  </div>
);

const Accordian = ({
  noteData,
  selectedDate,
  selectedDate1,
  key1,
  notesRef,
  setIsGraphUpdate,
}) => {
  const dispatch = useDispatch();

  const { selectedCompany } = useSelector((state) => state.companyReducer);
  const { user } = useSelector((state) => state.userReducer);

  const [historyText, setHistory] = useState([]);

  const [isAIOperatRunning, setIsAIOperatRunning] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [editNote, setEditNote] = useState("");

  const quillRef = useRef(null);

  let updatedNote = noteData?.val;

  const inputDate = new Date(noteData?.date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = inputDate?.toLocaleDateString("en-US", options);

  const handleRevert = () => {
    if (historyText.length) {
      setEditNote(historyText[historyText.length - 1]);
      setHistory(historyText.pop());
    }
  };

  const replaceText = async (myKey, myText) => {
    if (myText) {
      const text = await autoCorrect(myKey, myText);
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        range.deleteContents();
        const textNode = document.createTextNode(text);
        range.insertNode(textNode);
        selection.removeAllRanges();
      }
    }
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {
        "auto-correct": () =>
          replaceText("auto-correct", window.getSelection().toString()),
        rephrase: () =>
          replaceText("rephrase", window.getSelection().toString()),
        summarise: () =>
          replaceText("summarise", window.getSelection().toString()),
        "continue-writing": async () => {
          const editor = document.querySelector(".ql-editor");
          const quillText = editor.innerHTML;
          const resp = await autoCorrect("continue-writing", quillText);
          editor.innerHTML = resp;
        },
        undo: () => quillRef.current.getEditor().history.undo(),
        redo: () => quillRef.current.getEditor().history.redo(),
      },
    },
  };

  const handleUpdate = (content) => {
    // setHistory([...historyText, content]);
    updatedNote = content;
  };

  const hanldeSave = async () => {
    setEditNote(updatedNote);
    const payload = {
      date: selectedDate,
      key: key1,
      value: updatedNote,
      date1: selectedDate1,
      companyId: selectedCompany?._id,
    };

    await dispatch(addNote(payload));
    setIsEdit(!isEdit);

    setIsGraphUpdate((prev) => !prev);
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "size",
    "list", // Include list format
    "underline",
    "link",
    // other formats you might need
  ];

  return (
    <React.Fragment>
      <Accordion
        style={{ width: "100%" }}
        className="accordian_notes"
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <ul>
              <li>
                <b>
                  Notes{" "}
                  {noteData?.date
                    ? `- Last Updated: [ ${formattedDate} ] `
                    : ""}
                </b>
              </li>
            </ul>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isEdit ? (
            <>
              <CustomToolbar
                historyText={historyText}
                handleRevert={handleRevert}
              />
              <ReactQuill
                ref={quillRef}
                style={{
                  marginRight: "30px",
                }}
                theme="snow"
                value={editNote ? editNote : noteData?.val}
                onChange={handleUpdate}
                modules={modules}
                formats={formats}
                id="myInput"
              />
            </>
          ) : (
            <div ref={notesRef}>
              <Typography>
                <span
                  style={{
                    fontSize: 14,
                    color: "#606060",
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "30px",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: noteData?.val,
                    }}
                  ></div>
                </span>
              </Typography>
            </div>
          )}
          {isEdit ? (
            <img
              alt="save_icon"
              className="notes_edit_btn"
              width="30"
              onClick={hanldeSave}
              src={SaveIcon}
            />
          ) : (
            <img
              alt="edit_icon"
              className="notes_edit_btn"
              onClick={() => {
                if (!user.isClient) setIsEdit(!isEdit);
              }}
              src={EditIcon}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default Accordian;
