import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { BASE_URL } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyDetails } from "../../redux/actions/companyDetails.js";
import axios from "axios";
export default function CallBackComponent() {
  const hasFetchedRef = useRef(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(false);
  const { company } = useSelector((state) => state.companyDetailReducer);
  const exchangeCode = async (authorizationCode) => {
    try {
      const response = await axios.post(`${BASE_URL}/exchange_code`, {
        code: authorizationCode,
        companyId: company._id,
      });

      setError(false);
      setConnected(true);
      localStorage.setItem("access_token", response.data.access_token);
      const payload2 = {
        companyId: company._id,
      };
      dispatch(fetchCompanyDetails(payload2));
      setTimeout(() => {
        window.close();
      }, 1500);
    } catch (error) {
      console.error("Error exchanging authorization code:", error);
      setError(true);
    }
  };
  useEffect(() => {
    const query = new URLSearchParams(location.search);

    const authorizationCode = query.get("code");
    const state = query.get("state");

    if (authorizationCode && !hasFetchedRef.current) {
      if (state !== "fnEZiY2jxqAEMeE9NwNoc2wXK28Hzi74") {
        console.error("State parameter mismatch");
        return;
      }
      hasFetchedRef.current = true;
      exchangeCode(authorizationCode);
    }
  }, [location]);

  return (
    <React.Fragment>
      {connected ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
            color: "#2E7D32",
            flexDirection: "column",
          }}
        >
          <CheckCircleOutlineIcon
            color="success"
            style={{ fontSize: "100px" }}
          />
          <h1>Authorization Successful</h1>
        </div>
      ) : error ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
            color: "red",
            flexDirection: "column",
            gap: 15,
          }}
        >
          <CancelIcon color="error" style={{ fontSize: "100px" }} />
          <h1>Authorization Failed</h1>
          <div>Please Try again </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
            color: "#007bff",
          }}
        >
          <h1>Processing QuickBooks Authorization...</h1>
        </div>
      )}
    </React.Fragment>
  );
}
