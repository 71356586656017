import {
  PDFViewer,
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CashFlowLogo from "../../images/logo.png";
import { Layout } from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { CardWrapper } from "../../components";
import { clientCheck, handlInfo } from "../../utils/utilFunctions";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
  },
  imageContainer: {
    width: 200, // Set the width of the container
    height: 150, // Set the height of the container
  },
  image: {
    objectFit: "cover", // Ensure the image fully covers the container
    width: "50%", // Make the image fill the container width
    height: "auto", // Make the image fill the container height
  },

  text: {
    alignSelf: "center",
    // color: "black",
    color: "white",
    padding: "16 10",
    fontWeight: "800",
    fontSize: "25px",
  },

  companyName: {
    fontSize: "25px",
    padding: "10px ",
  },

  footer: {
    position: "absolute",
    bottom: 20, // Adjust this value to change the vertical position of the footer
    left: 10,
    right: 0,
    fontSize: 10, // Adjust the font size of the footer text
  },

  footerLogoView: {
    position: "absolute",
    bottom: 1, // Adjust this value to change the vertical position of the footer
    left: 630,
    right: 0,
    display: "flex",
  },
  footerImage: {
    // objectFit: "cover", // Ensure the image fully covers the container
    width: "90%", // Make the image fill the container width
    height: "auto", // Make the image fill the container height
  },

  footerPageText: {
    fontSize: "10px",
  },

  notesImage: {
    padding: "10px 50px",
  },

  clientCompany: {
    padding: "5px",
    fontSize: "20px",
  },
});

const AllKeys = [
  "ownGoal",
  "industryAvg",
  "comprtvRatio",
  "fmfInvenGraph", // added because in ADND company FMF inventory not showing when selecting all in PDF
  "fmfInvenWhatIf",
  "fmfServiceWhatIf",
  "trend",
  "exCntrl",
  "debtEquity",
  "ebitda",
  "misMatch",
  "cashFlowAct",
  "simpleVal",
  "simpleValMilestone",
  "currentRatioGuage",
  "currentRatioChart",
  "quickRatioGuage",
  "quickRatioChart",
  "debtEqRatioGuage",
  "debtEqRatioChart",
  "grossMarginGuage",
  "grossMarginChart",
  "netMarginGuage",
  "netMarginChart",
  "saleAssetsGuage",
  "saleAssetsChart",
  "ebidtaFDGuage",
  "ebidtaFDChart",
  "roaGuage",
  "roaChart",

  "roiGuage",
  "roiChart",

  "invTurnoverGuage",
  "invTurnoverChart",

  "arTurnoverGuage",
  "arTurnoverChart",

  "apTurnoverGuage",
  "apTurnoverChart",

  "dcohGuage",
  "dcohChart",

  "dscrGuage",
  "dscrChart",

  "ourPerPlan",
  "calculateTran",
  "estimateValSavingPlan",
];

const ExtendedPageTitleList = {
  ownGoal: ["analysisOwnGoal"],
  industryAvg: ["analysisIndustry"],
};

const HeaderText = {
  ownGoal: "Owner Goal",
  industryAvg: "Industry Average",
  fmfInvenGraph: "Fast Money Formula (Inventory)",
  fmfInvenWhatIf: "Fast Money Formula (Inventory)",
  fmfServiceGraph: "Fast Money Formula (Payroll)",
  fmfServiceWhatIf: "Fast Money Formula (Payroll)",
  trend: "Trends",
  exCntrl: "Expense Control",
  debtEquity: "Debt Equity",
  ebitda: "EBITDA",
  misMatch: "Mis-Matched Financing",
  cashFlowAct: "Cash Flow",
  comprtvRatio: "Comparative Ratio",
  simpleVal: "Simple Valuation",
  currentRatioGuage: "Current Ratio",
  quickRatioGuage: "Quick Ratio",
  debtEqRatioGuage: "Debt to Equity Ratio",
  grossMarginGuage: "Gross Profit Margin",
  netMarginGuage: "Net Profit Margin",
  saleAssetsGuage: "Sales To Assets",
  ebidtaFDGuage: "EBIDTA",
  roaGuage: "ROA",
  roiGuage: "ROI",
  invTurnoverGuage: "Inventory Turnover",
  arTurnoverGuage: "AR Turnover",
  apTurnoverGuage: "AP Turnover",
  dcohGuage: "Days Cash On Hand",
  dscrGuage: "DSCR",
  ourPerPlan: "Start With End In Mind",
};

function PDF() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userReducer);
  const { company } = useSelector((state) => state.companyDetailReducer);

  const { pdfGraph, notesDates } = useSelector(
    (state) => state.pdfGraphReducer
  );

  const { calculationRank } = useSelector(
    (state) => state.financialDoctorReducer
  );

  const [selectedImgs, setSelectedImgs] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackType, setSnackType] = useState(false);
  const [message, setMessage] = useState("");

  const currentDate = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  useEffect(() => {
    setSelectedImgs([]);
  }, [company]);

  let pageNumber = 1;

  const selectAll = (e) => {
    let available = [];
    if (e.target.checked) {
      AllKeys.map((key) => {
        if (pdfGraph.images[key]) available = [...available, key];
      });

      setSelectedImgs(available);
    } else {
      setSelectedImgs([]);
    }
  };

  console.log("selectedImgs", selectedImgs);

  const handleSelect = (val, e) => {
    if (typeof val == "object") {
      if (e.target.checked) {
        let updateVal = [...selectedImgs];

        val.forEach((va, i) => {
          if (!pdfGraph.images[va]) {
            setOpen(true);
            setSnackType("");
            setMessage("No image selected");
            return;
          }
          updateVal = [...updateVal, va];
        });

        return setSelectedImgs(updateVal);
      }

      setSelectedImgs((prev) =>
        prev.filter((imgName) => !val.includes(imgName))
      );
    } else {
      if (e.target.checked) {
        if (!pdfGraph.images[val]) {
          setOpen(true);
          setSnackType("");
          setMessage("No image selected");
          return;
        }
        return setSelectedImgs([...selectedImgs, val]);
      }

      selectedImgs.filter((imgName) => imgName !== val);

      setSelectedImgs(() => selectedImgs.filter((imgName) => imgName !== val));
    }
  };

  const handlePage = () => {
    pageNumber += 1;
    return pageNumber - 1;
  };

  console.log("user.logo", user.logo);

  return (
    <>
      <React.Fragment>
        <Layout>
          <CardWrapper>
            <Grid
              container
              item
              xs={12}
              style={{ display: "flex", marginBottom: "70px" }}
            >
              <Box style={{ display: "flex" }}>
                <InfoOutlinedIcon
                  onClick={() => handlInfo("pdf")}
                  className="infor_icon"
                  style={{
                    margin: "0px 10px 0 0",
                    fontSize: "25px",
                  }}
                />

                <Typography>
                  To generate a PDF of your selected charts, ensure you've
                  viewed the desired charts in your calculation, and the app
                  will render only the last chart for inclusion in the PDF.
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={3.5}>
                <Grid item xs={12} style={{ margin: "0px 0px 30px 0px" }}>
                  <FormControlLabel
                    style={{
                      fontWeight: "800",
                    }}
                    control={
                      <Checkbox
                        // checked={selectedImgs.includes("ownGoal")}
                        onClick={(e) => selectAll(e)}
                      />
                    }
                    label="Select All Available"
                  />
                </Grid>
                <Typography className="pdf_heading">Hidden Cash </Typography>
                <Grid item xs={12} style={{ margin: "0px 0px 30px 0px" }}>
                  <FormControlLabel
                    style={pdfGraph?.images?.ownGoal ? {} : { color: "gray" }}
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("ownGoal")}
                        onClick={(e) => handleSelect("ownGoal", e)}
                      />
                    }
                    label="Mining For Hidden Cash (Own Goals)"
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: "30px 0px" }}>
                  <FormControlLabel
                    style={pdfGraph.images.industryAvg ? {} : { color: "gray" }}
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("industryAvg")}
                        onClick={(e) => handleSelect("industryAvg", e)}
                      />
                    }
                    label="Mining For Hidden Cash (Industry Average)"
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: "30px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.comprtvRatio ? {} : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("comprtvRatio")}
                        onClick={(e) => handleSelect("comprtvRatio", e)}
                      />
                    }
                    label="Comparative Ratios"
                  />
                </Grid>
                <Typography className="pdf_heading">Fast Money </Typography>
                <Grid item xs={12} style={{ margin: "0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.fmfInvenWhatIf ? {} : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("fmfInvenWhatIf")}
                        onClick={(e) =>
                          handleSelect(["fmfInvenGraph", "fmfInvenWhatIf"], e)
                        }
                      />
                    }
                    label="Fast Money Formula (Inventory Optimized)"
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: "30px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.fmfServiceWhatIf ? {} : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("fmfServiceWhatIf")}
                        onClick={(e) =>
                          handleSelect(
                            ["fmfServiceGraph", "fmfServiceWhatIf"],
                            e
                          )
                        }
                      />
                    }
                    label="Fash Money Formula (Payroll Optimized)"
                  />
                </Grid>
                <Typography className="pdf_heading">Home Run </Typography>
                <Grid item xs={12} style={{ margin: "0px 0px" }}>
                  <FormControlLabel
                    style={pdfGraph.images.trend ? {} : { color: "gray" }}
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("trend")}
                        onClick={(e) => handleSelect("trend", e)}
                      />
                    }
                    label="Trends"
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: "30px 0px" }}>
                  <FormControlLabel
                    style={pdfGraph.images.exCntrl ? {} : { color: "gray" }}
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("exCntrl")}
                        onClick={(e) => handleSelect("exCntrl", e)}
                      />
                    }
                    label="Expense Control"
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: "30px 0px" }}>
                  <FormControlLabel
                    style={pdfGraph.images.debtEquity ? {} : { color: "gray" }}
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("debtEquity")}
                        onClick={(e) => handleSelect("debtEquity", e)}
                      />
                    }
                    label="Debt To Equity Ratio"
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: "30px 0px" }}>
                  <FormControlLabel
                    style={pdfGraph.images.ebitda ? {} : { color: "gray" }}
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("ebitda")}
                        onClick={(e) => handleSelect("ebitda", e)}
                      />
                    }
                    label="EBITDA"
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: "30px 0px" }}>
                  <FormControlLabel
                    style={pdfGraph.images.misMatch ? {} : { color: "gray" }}
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("misMatch")}
                        onClick={(e) => handleSelect("misMatch", e)}
                      />
                    }
                    label="Mis-Matched Financing"
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: "30px 0px 30px 0px" }}>
                  <FormControlLabel
                    style={pdfGraph.images.cashFlowAct ? {} : { color: "gray" }}
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("cashFlowAct")}
                        onClick={(e) => handleSelect("cashFlowAct", e)}
                      />
                    }
                    label="Cash Flow Activity Pattern"
                  />
                </Grid>

                <Typography className="pdf_heading">
                  Simple Valuation Formula{" "}
                </Typography>
                <Grid item xs={12} style={{ margin: "0px 0px 30px 0px" }}>
                  <FormControlLabel
                    style={pdfGraph.images.simpleVal ? {} : { color: "gray" }}
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("simpleVal")}
                        onClick={(e) =>
                          handleSelect(["simpleVal", "simpleValMilestone"], e)
                        }
                      />
                    }
                    label="Simple Valuation"
                  />
                </Grid>

                <Typography className="pdf_heading">
                  Start With End In Mind{" "}
                </Typography>
                <Grid item xs={12} style={{ margin: "0px 0px 30px 0px" }}>
                  <FormControlLabel
                    style={pdfGraph.images.ourPerPlan ? {} : { color: "gray" }}
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("ourPerPlan")}
                        onClick={(e) =>
                          handleSelect(
                            [
                              "ourPerPlan",
                              "calculateTran",
                              "estimateValSavingPlan",
                              "startWithEnd",
                            ],
                            e
                          )
                        }
                      />
                    }
                    label="Start With End In Mind"
                  />
                </Grid>

                <Typography className="pdf_heading">
                  Financial Doctor{" "}
                </Typography>
                <Grid item xs={12} style={{ margin: "0px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.currentRatioGuage
                        ? calculationRank.currentRatio === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("currentRatioGuage")}
                        onClick={(e) =>
                          handleSelect(
                            ["currentRatioGuage", "currentRatioChart"],
                            e
                          )
                        }
                      />
                    }
                    label="Current Ratio"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.quickRatioGuage
                        ? calculationRank.quickRatio === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("quickRatioGuage")}
                        onClick={(e) =>
                          handleSelect(
                            ["quickRatioGuage", "quickRatioChart"],
                            e
                          )
                        }
                      />
                    }
                    label="Quick Ratio"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.debtEqRatioGuage
                        ? calculationRank.debtEquityRatio === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("debtEqRatioGuage")}
                        onClick={(e) =>
                          handleSelect(
                            ["debtEqRatioGuage", "debtEqRatioChart"],
                            e
                          )
                        }
                      />
                    }
                    label="Debt To Equity Ratio"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.grossMarginGuage
                        ? calculationRank.grossMargin === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("grossMarginGuage")}
                        onClick={(e) =>
                          handleSelect(
                            ["grossMarginGuage", "grossMarginChart"],
                            e
                          )
                        }
                      />
                    }
                    label="Gross Profit Margin"
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.netMarginGuage
                        ? calculationRank.netMargin === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("netMarginGuage")}
                        onClick={(e) =>
                          handleSelect(["netMarginGuage", "netMarginChart"], e)
                        }
                      />
                    }
                    label="Net Profit Margin"
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.saleAssetsGuage
                        ? calculationRank.salesToAssets === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("saleAssetsGuage")}
                        onClick={(e) =>
                          handleSelect(
                            ["saleAssetsGuage", "saleAssetsChart"],
                            e
                          )
                        }
                      />
                    }
                    label="Sales To Assets"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.ebidtaFDGuage
                        ? calculationRank.ebidta === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("ebidtaFDGuage")}
                        onClick={(e) =>
                          handleSelect(["ebidtaFDGuage", "ebidtaFDChart"], e)
                        }
                      />
                    }
                    label="EBIDTA"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.roaGuage
                        ? calculationRank.ROA === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("roaGuage")}
                        onClick={(e) =>
                          handleSelect(["roaGuage", "roaChart"], e)
                        }
                      />
                    }
                    label="ROA"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.roiGuage
                        ? calculationRank.ROI === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("roiGuage")}
                        onClick={(e) =>
                          handleSelect(["roiGuage", "roiChart"], e)
                        }
                      />
                    }
                    label="ROI"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.invTurnoverGuage
                        ? calculationRank.inventoryTurnover === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("invTurnoverGuage")}
                        onClick={(e) =>
                          handleSelect(
                            ["invTurnoverGuage", "invTurnoverChart"],
                            e
                          )
                        }
                      />
                    }
                    label="Inventory Turnover"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.arTurnoverGuage
                        ? calculationRank.arTurnover === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("arTurnoverGuage")}
                        onClick={(e) =>
                          handleSelect(
                            ["arTurnoverGuage", "arTurnoverChart"],
                            e
                          )
                        }
                      />
                    }
                    label="AR Turnover"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.apTurnoverGuage
                        ? calculationRank.apTurnover === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("apTurnoverGuage")}
                        onClick={(e) =>
                          handleSelect(
                            ["apTurnoverGuage", "apTurnoverChart"],
                            e
                          )
                        }
                      />
                    }
                    label="AP Turnover"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.dcohGuage
                        ? calculationRank.dcoh === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("dcohGuage")}
                        onClick={(e) =>
                          handleSelect(["dcohGuage", "dcohChart"], e)
                        }
                      />
                    }
                    label="Days Cash On Hand"
                  />
                </Grid>

                <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                  <FormControlLabel
                    style={
                      pdfGraph.images.dscrGuage
                        ? calculationRank.dscr === "Terrible"
                          ? { color: "red" }
                          : {}
                        : { color: "gray" }
                    }
                    control={
                      <Checkbox
                        checked={selectedImgs.includes("dscrGuage")}
                        onClick={(e) =>
                          handleSelect(["dscrGuage", "dscrChart"], e)
                        }
                      />
                    }
                    label="DSCR"
                  />
                </Grid>
              </Grid>
              <Grid item xs={8.5}>
                <PDFViewer
                  showToolbar={clientCheck(user, dispatch)}
                  width="100%"
                  height="100%"
                >
                  <Document>
                    <Page
                      size={{ width: 800, height: 600 }}
                      style={styles.page}
                    >
                      <Image
                        src={user.logo}
                        // src={{
                        //   uri: user.logo,
                        //   method: "GET",
                        //   headers: { "Cache-Control": "no-cache" },
                        //   body: "",
                        // }}
                        style={styles.image}
                      />
                      <Text style={styles.companyName}>{user.profileName}</Text>
                      <Text>--- Advisory Report ---</Text>
                      <Text style={styles.clientCompany}>
                        For {company.companyName}
                      </Text>
                      <Text>{formattedDate.toString()}</Text>

                      <View style={styles.footer}>
                        <Text style={styles.footerPageText}>
                          Page {handlePage()}
                        </Text>{" "}
                        <View style={styles.footerLogoView}>
                          <View
                            style={{
                              display: "flex",
                            }}
                          >
                            <Text>Powered by:</Text>
                            <Image
                              src={CashFlowLogo}
                              style={styles.footerImage}
                            />
                          </View>
                        </View>
                      </View>
                    </Page>
                    {selectedImgs.map((imgs) => (
                      <>
                        <Page size={{ width: 800, height: 700 }}>
                          <View
                            style={{
                              marginBottom: "30px",
                              backgroundColor: "#0c0f7c",
                            }}
                          >
                            <Text style={styles.text}>{HeaderText[imgs]}</Text>
                          </View>
                          <Image
                            style={{ padding: "25px 50px" }}
                            src={pdfGraph.images[imgs]}
                          />
                          <View style={styles.footer}>
                            <Text style={styles.footerPageText}>
                              Page {handlePage()}
                            </Text>{" "}
                            <View style={styles.footerLogoView}>
                              <View
                                style={{
                                  display: "flex",
                                }}
                              >
                                <Text>Powered by:</Text>
                                <Image
                                  src={CashFlowLogo}
                                  style={styles.footerImage}
                                />
                              </View>
                            </View>
                          </View>
                        </Page>
                        {ExtendedPageTitleList[imgs]?.map((extended) => (
                          <Page size={{ width: 800, height: 800 }}>
                            <Image
                              style={{ padding: "25px 50px" }}
                              src={pdfGraph.images[extended]}
                            />
                            <View style={styles.footer}>
                              <Text style={styles.footerPageText}>
                                Page {handlePage()}
                              </Text>{" "}
                              <View style={styles.footerLogoView}>
                                <View
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <Text>Powered by:</Text>
                                  <Image
                                    src={CashFlowLogo}
                                    style={styles.footerImage}
                                  />
                                </View>
                              </View>
                            </View>
                          </Page>
                        ))}
                        {pdfGraph.notes[imgs] &&
                        pdfGraph.notes[imgs] !== "data:," ? (
                          <>
                            <Page size={{ width: 800, height: 600 }}>
                              <View
                                style={{
                                  marginBottom: "30px",
                                  backgroundColor: "#0c0f7c",
                                }}
                              >
                                <Text style={styles.text}>
                                  {HeaderText[imgs]}
                                </Text>
                              </View>
                              <Text style={styles.notesImage}>
                                Notes - Last Updated: {notesDates[imgs]}
                              </Text>
                              <Image
                                src={pdfGraph.notes[imgs]}
                                // style={styles.notesImage}
                                style={{ padding: "25px 50px" }}
                              />
                              <View style={styles.footer}>
                                <Text style={styles.footerPageText}>
                                  Page {handlePage()}
                                </Text>{" "}
                                <View style={styles.footerLogoView}>
                                  <View
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <Text>Powered by:</Text>
                                    <Image
                                      src={CashFlowLogo}
                                      style={styles.footerImage}
                                    />
                                  </View>
                                </View>
                              </View>
                            </Page>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </Document>
                </PDFViewer>
              </Grid>
            </Grid>
          </CardWrapper>
        </Layout>
        {/* <SnackBar
        // setOpen={setOpenSnackBar}
        open={openSnackbar}
        snackType={snackType}
        msg={message}
      /> */}
      </React.Fragment>
    </>
  );
}

export default PDF;
